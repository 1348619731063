import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-solid-svg-icons";
import { FC } from "react";
import { useAtomValue, useSetAtom } from "jotai";
import { pointsTokenAtom } from "@/stores";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { api } from "@/config/network";
import { Button } from "@/components/ui/button";
import ItemDeletionModal from "../modals/ItemDeletionModal";

type DeleteProps = {
  itemID: string;
  guildID: string;
  memberID?: string;
  preview?: boolean;
};

const DeleteButton: FC<DeleteProps> = (props) => {
  const { itemID, guildID, memberID, preview } = props;

  const token = useAtomValue(pointsTokenAtom);
  const queryClient = useQueryClient();
  const removeItem = useMutation({
    mutationFn: async ({
      guildID,
      memberID,
      itemID,
    }: {
      guildID: string;
      memberID: string;
      itemID: string;
    }) => {
      if (!token) return;
      const res = await api.delete(
        `/guilds/${guildID}/members/${memberID}/${itemID}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return res.data;
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["currentMember", guildID],
      });
      queryClient.invalidateQueries({
        queryKey: ["member", guildID, memberID],
      });
    },
  });
  if (preview) {
    return (
      <Button
        size="icon"
        variant="destructive"
        className="h-8 w-8 bg-red-500/15 text-red-500 hover:bg-red-500 hover:text-white"
        onClick={() => {
          if (guildID && memberID && itemID) {
            return removeItem.mutate({ guildID, memberID, itemID });
          }
        }}
      >
        <FontAwesomeIcon icon={faTrash} />
      </Button>
    );
  }

  return <ItemDeletionModal guildID={guildID} itemID={itemID} />;
};

export default DeleteButton;
