import Body from "@/components/Body";
import Footer from "@/components/Footer";
import Navbar from "@/components/Navbar";

const Terms = () => {
  return (
    <>
      <Navbar />
      <Body className="text-neutral-700 dark:text-neutral-300/90 font-light">
        <h1 className="mt-8 text-4xl font-bold">Terms of Service</h1>
        <p className="mb-4 opacity-50">Effective date: August 19, 2022</p>
        <p>
          Innatical, LLC ("us", "we", or "our") operates the{" "}
          <a className="text-brand-500" href="https://points.bot">
            points.bot
          </a>{" "}
          website (hereinafter referred to as the "Service").
        </p>
        <h5 className="mt-4 mb-2 font-bold text-lg">1. Terms</h5>
        <p>
          By accessing the website at{" "}
          <a className="text-brand-500" href="http://points.bot">
            points.bot
          </a>
          , you are agreeing to be bound by these terms of service, all
          applicable laws and regulations, and agree that you are responsible
          for compliance with any applicable local laws. If you do not agree
          with any of these terms, you are prohibited from using or accessing
          this site. The materials contained in this website are protected by
          applicable copyright and trademark law.
        </p>
        <h5 className="mt-4 mb-2 font-bold text-lg">
          2. Use of Service and Creation of Content
        </h5>
        <ol type="a" className="list-decimal -pl-8 ml-8">
          <li className="mb-2">
            You agree to not create or use any user created content that
            contains any of the following:
            <ol type="i" className="list-decimal list-outside -pl-8 ml-8">
              <li>NSFW content</li>
              <li>Illegal content</li>
              <li>Sexually explicit content</li>
              <li>Drug references</li>
              <li>Bullying</li>
              <li>Anything damaging to Innatical, LLC or its associates</li>
            </ol>
          </li>
          <li>
            You agree not to use the Service in order to:
            <ol type="i" className="list-decimal list-outside -pl-8 ml-8">
              <li>
                post, upload, transmit or otherwise disseminate information that
                is nsfw, sexually explicit, illegal, drug related, or otherwise
                objectionable to Innatical, LLC
              </li>
              <li>
                defame, libel, ridicule, mock, stalk, threaten, harass,
                intimidate or abuse anyone;
              </li>
              <li>
                engage in conduct that is fraudulent or illegal or otherwise
                harmful to Innatical, LLC or any other user;
              </li>
              <li>
                upload or transmit (or attempt to upload or transmit) files that
                contain viruses, Trojan horses, worms, time bombs, cancelbots,
                corrupted files or data, or any other similar software or
                programs or engage in any other activity that may damage the
                operation of the Service or other users' computers;
              </li>
              <li>
                violate the contractual, personal, intellectual property or
                other rights of any party including using, uploading,
                transmitting, distributing, or otherwise making available any
                information made available through the Service in any manner
                that infringes any copyright, trademark, patent, trade secret,
                or other right of any party (including rights of privacy or
                publicity);
              </li>
              <li>
                attempt to obtain passwords or other private information from
                other members;
              </li>
              <li>
                improperly use support channels or complaint buttons to make
                false reports to us;
              </li>
              <li>
                develop, distribute, or publicly inform other members of "auto"
                software programs, "macro" software programs or other "cheat
                utility" software program or applications in violation of the
                applicable license agreements; or
              </li>
              <li>
                exploit, distribute or publicly inform other members of any
                service error, miscue or bug which gives an unintended
                advantage; violate any applicable laws or regulations; or
                promote or encourage illegal activity including, but not limited
                to, hacking, cracking or distribution of counterfeit software,
                compromised accounts, or cheats or hacks for the Service.
              </li>
              <li>
                You agree to report any non-illegal but TOS breaking behavior to
                the Innatical, LLC staff, and to report any illegal behavior to
                the appropriate authorities.
              </li>
            </ol>
          </li>
        </ol>
        <h5 className="mt-4 mb-2 font-bold text-lg">3. Disclaimer</h5>
        <ol type="a">
          <li>
            The materials on Innatical, LLC's website are provided on an 'as is'
            basis. Innatical, LLC makes no warranties, expressed or implied, and
            hereby disclaims and negates all other warranties including, without
            limitation, implied warranties or conditions of merchantability,
            fitness for a particular purpose, or non-infringement of
            intellectual property or other violation of rights.
          </li>
          <li>
            Further, Innatical, LLC does not warrant or make any representations
            concerning the accuracy, likely results, or reliability of the use
            of the materials on its website or otherwise relating to such
            materials or on any sites linked to this site.
          </li>
        </ol>
        <h5 className="mt-4 mb-2 font-bold text-lg">4. Limitations</h5>
        <p>
          In no event shall Innatical, LLC or its suppliers be liable for any
          damages (including, without limitation, damages for loss of data or
          profit, or due to business interruption) arising out of the use or
          inability to use the materials on Innatical, LLC's website, even if
          Innatical, LLC or a Innatical, LLC authorized representative has been
          notified orally or in writing of the possibility of such damage.
          Because some jurisdictions do not allow limitations on implied
          warranties, or limitations of liability for consequential or
          incidental damages, these limitations may not apply to you.
        </p>
        <h5 className="mt-4 mb-2 font-bold text-lg">
          5. Accuracy of materials
        </h5>
        <p>
          The materials appearing on Innatical, LLC's website could include
          technical, typographical, or photographic errors. Innatical, LLC does
          not warrant that any of the materials on its website are accurate,
          complete or current. Innatical, LLC may make changes to the materials
          contained on its website at any time without notice. However
          Innatical, LLC does not make any commitment to update the materials.
        </p>
        <h5 className="mt-4 mb-2 font-bold text-lg">6. Links</h5>
        <p>
          Innatical, LLC has not reviewed all of the sites linked to its website
          and is not responsible for the contents of any such linked site. The
          inclusion of any link does not imply endorsement by Innatical, LLC of
          the site. Use of any such linked website is at the user's own risk.
        </p>
        <h5 className="mt-4 mb-2 font-bold text-lg">7. Violation of TOS</h5>
        <p>
          Consequences for violation of this TOS shall be determined by the
          Innatical, LLC staff. Innatical, LLC reserves the right to ban a user
          from using the Service for any reason and any offending content will
          be permanently removed. Innatical, LLC also reserves the right to not
          disclose the reasoning for a ban and/or content takedown. There shall
          be no petitioning of bans and/or content takedowns nor action taken to
          harm Innatical, LLC because of a ban and/or content takedown. If
          action is taken to harm Innatical, LLC, full legal force will be used
          against the offender.
        </p>
        <h5 className="mt-4 mb-2 font-bold text-lg">
          8. Class Waiver and Dispute Resolution
        </h5>
        <p>
          THIS SECTION ONLY APPLIES TO YOU IF YOU ARE A UNITED STATES RESIDENT.
          <br />
          You and Innatical, LLC agree that these Terms affect interstate
          commerce and that the Federal Arbitration Act governs the
          interpretation and enforcement of these arbitration provisions.
          <br />
          <br />
          Most disputes can be resolved without resorting to arbitration. In the
          event of a dispute, you agree to provide us notice of the dispute.
          This notice must provide a brief, written description of the dispute,
          the relief requested and the contact information of the party giving
          it. You must send any such notice to Innatical, LLC by email to
          support@atiktech.co.
          <br />
          <br />
          The parties agree to use their best efforts to settle any dispute,
          claim, question, or disagreement directly through consultation with
          one another, and good faith negotiations shall be a condition to
          either party initiating a lawsuit or arbitration.
          <br />
          <br />
          Innatical, LLC and you agree to resolve any dispute in an individual
          capacity, and not on behalf of, or as part of, any purported class,
          consolidated, or representative proceeding.
          <br />
          <br />
          The arbitrator cannot combine more than one person's or entity's
          claims into a single case, and cannot preside over any consolidated,
          class or representative proceeding (unless we agree otherwise). And,
          the arbitrator's decision or award in one person's or entity's case
          can only impact the person or entity that brought the claim, not other
          Innatical, LLC customers, and cannot be used to decide other disputes
          with other customers.
          <br />
          <br />
          If any court or arbitrator determines that the
          class/consolidated/representative action waiver set forth in this
          section is void or unenforceable for any reason or that arbitration
          can proceed on a class, consolidated, or representative basis, then
          the disputes, claims, or controversies will not be subject to
          arbitration.
          <br />
          <br />
          This Class Action Waiver and Arbitration section shall survive any
          termination of your account or the Services.
        </p>
        <h5 className="mt-4 mb-2 font-bold text-lg">9. Modifications</h5>
        <p>
          Innatical, LLC may revise these terms of service for its website at
          any time without notice. By using this website you are agreeing to be
          bound by the then current version of these terms of service.
        </p>
        <h5 className="mt-4 mb-2 font-bold text-lg">10. Governing Law</h5>
        <p>
          These terms and conditions are governed by and construed in accordance
          with the laws of Minnesota and you irrevocably submit to the exclusive
          jurisdiction of the courts in that State or location.
        </p>
      </Body>
      <Footer />
    </>
  );
};

export default Terms;
