import { FC } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { api } from "@/config/network";
import { useAtomValue } from "jotai";
import { pointsTokenAtom } from "@/stores";
import { Button } from "@/components/ui/button";

type RecoverProps = {
  guildID: string;
  itemID: string;
  memberID?: string;
  cost?: number;
};

const RefundButton: FC<RecoverProps> = (props) => {
  const { guildID, memberID, itemID } = props;
  const token = useAtomValue(pointsTokenAtom);
  const queryClient = useQueryClient();
  const refundItem = useMutation({
    mutationFn: async ({
      guildID,
      itemID,
    }: {
      guildID: string;
      itemID: string;
    }) => {
      if (!token) return;
      const res = await api.post(
        `/guilds/${guildID}/shop/${itemID}`,
        undefined,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return res.data;
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["currentMember", guildID],
      });
      queryClient.invalidateQueries({
        queryKey: ["shop", guildID],
      });
    },
  });

  return (
    <Button
      variant="secondary"
      className="w-full"
      onClick={() => {
        if (memberID) {
          refundItem.mutate({ guildID, itemID });
        }
      }}
    >
      Refund
    </Button>
  );
};
export default RefundButton;
