import Body from "@/components/Body";
import Navbar from "@/components/Navbar";
import { useEffect } from "react";
import { Link, Route, Routes } from "react-router-dom";
import UserLookup from "./user";
import GuildLookup from "./guild";
import Footer from "@/components/Footer";
import { twMerge } from "tailwind-merge";
import PremiumLookup from "./premium";

const Admin = () => {
  useEffect(() => {
    console.info(
      "Hey, if you found this then you must be snooping around!\nThis page is to help me debug and provide support to users. Adam is the only one who can see this page."
    );
  }, []);

  const isGuildLookup = window.location.pathname.includes("/guilds");
  const isPremiumManage = window.location.pathname.includes("/premium");

  return (
    <>
      <Navbar />
      <Body>
        <h1 className="mt-8 text-4xl font-bold">Admin Panel</h1>
        <p className="mb-4 opacity-50">
          This page is only visible to the developer and is used to debug and
          provide support to users.
        </p>
        <div className="flex items-center">
          <Link to="/admin">
            <button
              className={twMerge(
                "rounded-xl bg-park-50 rounded-r-none bg-primary-foreground border border-border py-2 px-3.5 text-sm",
                !isGuildLookup && !isPremiumManage && "bg-secondary"
              )}
            >
              User Lookup
            </button>
          </Link>
          <Link to="/admin/guilds">
            <button
              className={twMerge(
                " bg-park-50 rounded-l-none bg-primary-foreground border border-border py-2 px-3.5 border-l-0 text-sm",
                isGuildLookup && "bg-secondary"
              )}
            >
              Guild Lookup
            </button>
          </Link>
          <Link to="/admin/premium">
            <button
              className={twMerge(
                "rounded-xl bg-park-50 rounded-l-none bg-primary-foreground border border-border py-2 px-3.5 border-l-0 text-sm",
                isPremiumManage && "bg-secondary"
              )}
            >
              Premium
            </button>
          </Link>
        </div>
        <Routes>
          <Route path="/" element={<UserLookup />} />
          <Route path="guilds/" element={<GuildLookup />} />
          <Route path="premium/" element={<PremiumLookup />} />
        </Routes>
      </Body>
      <Footer />
    </>
  );
};

export default Admin;
