import { FC } from "react";
import { APIItem } from "@/types/points";
import ItemEditModal from "../modals/ItemEditModal";

type EditProps = {
  item: APIItem;
  guildID: string;
};

const EditButton: FC<EditProps> = (props) => {
  const { item, guildID } = props;

  return <ItemEditModal item={item} guildID={guildID} />;
};

export default EditButton;
