import React, { FC, ReactNode, useMemo } from "react";
import { APIItem, APIRole } from "@/types/points";
import { useCurrentMember } from "@/api/useCurrentMember";

import BuyButton from "./buttons/BuyButton";
import DeleteButton from "./buttons/DeleteButton";
import RefundButton from "./buttons/RefundButton";
import EditButton from "./buttons/EditButton";
import RecoverButton from "./buttons/RecoverButton";
import { API_URL } from "@/config/network";

export type ItemProps = {
  item: APIItem;
  permission?: "admin" | "role";
  refund?: boolean;
  guildID: string;
  roles?: APIRole[];
  preview?: boolean;
  dragHandle?: ReactNode;
};

const checkBackpack = (
  backpack: {
    id: string;
    amount?: number;
  }[],
  item: APIItem
) => {
  return backpack.some((bgItem) => {
    if (typeof bgItem === "string") return bgItem === item._id;
    else
      return (
        ((bgItem.amount || 1) >= (item?.amount || 1) &&
          bgItem.id === item._id) ||
        (item.role && bgItem.id === item._id)
      );
  });
};

const Item: FC<ItemProps> = (props) => {
  const {
    item,
    roles = [],
    preview = false,
    permission,
    guildID,
    refund,
    dragHandle,
  } = props;
  const { data: currentMember } = useCurrentMember(guildID);

  const showBuyButton = useMemo(() => {
    if (
      preview ||
      !guildID ||
      !item.available ||
      item.expire ||
      checkBackpack(currentMember?.items || [], item)
    )
      return false;
    return true;
  }, [currentMember, guildID, item, preview]);

  const showRefundButton = useMemo(() => {
    if (
      !refund ||
      !guildID ||
      preview ||
      !item.available ||
      item.expire ||
      !checkBackpack(currentMember?.items || [], item)
    )
      return false;
    return true;
  }, [currentMember, guildID, item, preview, refund]);

  const showRecoverButton = useMemo(() => {
    return !preview && !item.available && guildID && !!permission;
  }, [guildID, item.available, permission]);

  const sufficientBalance = useMemo(() => {
    if (item.cost) {
      return (currentMember?.balance || 0) >= item.cost;
    }
    return false;
  }, [currentMember, item.cost]);

  const soldOut = useMemo(() => {
    if (item.supply && item.supply <= 0) return true;
    return false;
  }, [item]);

  const role = useMemo(() => {
    return item.role
      ? roles?.find((role) => role._id === item.role)
      : undefined;
  }, [item, roles]);

  return (
    <div
      key={item._id}
      className="flex flex-col rounded-xl overflow-hidden shadow-xl bg-primary-foreground border border-border h-full"
    >
      {item.banner ? (
        <>
          <img
            className="w-full h-28 object-cover object-center pointer-events-none rounded-t-lg"
            src={`${API_URL}/cdn/guilds/${guildID}/${item.banner}`}
            alt={item.name}
          />
        </>
      ) : null}
      <div className="p-4">
        <div className="flex justify-between items-center gap-2">
          <div className="flex items-center gap-2  opacity-70">
            {dragHandle}
            <h4 className="text-lg font-bold">{item.name}</h4>
          </div>
          {!!permission &&
            (preview ? (
              <div>
                <DeleteButton
                  preview={preview}
                  itemID={item._id}
                  guildID={guildID}
                  memberID={currentMember?._id}
                />
              </div>
            ) : (
              <div className="flex items-center gap-0.5">
                <EditButton item={item} guildID={guildID} />
                <DeleteButton
                  preview={!!preview}
                  memberID={currentMember?._id}
                  guildID={guildID}
                  itemID={item._id}
                />
              </div>
            ))}
        </div>
        <div className="gap-1 flex items-center mt-1 flex-wrap">
          {item?.cost && (
            <div className="rounded-full bg-secondary px-2 py-1 text-[11px] font-semibold uppercase tracking-wide">
              {item.cost.toLocaleString()} points
            </div>
          )}
          {role && (
            <div className="rounded-full bg-secondary px-2 py-1 text-[11px] font-semibold uppercase tracking-wide">
              {role?.name}
            </div>
          )}
          {item?.expire && (
            <div className="rounded-full bg-secondary px-2 py-1 text-[11px] font-semibold uppercase tracking-wide">
              {item.expire}
            </div>
          )}
          {item?.amount && (
            <div className="rounded-full bg-secondary px-2 py-1 text-[11px] font-semibold uppercase tracking-wide">
              {currentMember?.items?.find((i) => i.id === item._id)?.amount ||
                0}
              /{item.amount} owned
            </div>
          )}
          {item?.supply && (
            <div className="rounded-full bg-secondary px-2 py-1 text-[11px] font-semibold uppercase tracking-wide">
              {item.supply} left
            </div>
          )}
        </div>
        <div className="mt-2 truncate line-clamp-2 opacity-80">
          <p>{item.description}</p>
        </div>
      </div>
      {(showRecoverButton || showBuyButton || showRefundButton) && (
        <div className="mt-auto pt-2 px-4 pb-4">
          {showBuyButton && (
            <BuyButton
              memberID={currentMember?._id}
              guildID={guildID}
              itemID={item._id}
              insufficientFunds={!sufficientBalance}
              soldOut={soldOut}
              cost={item.cost}
            />
          )}
          {showRefundButton && (
            <RefundButton
              guildID={guildID}
              memberID={currentMember?._id}
              itemID={item._id}
              cost={item.cost}
            />
          )}
          {showRecoverButton && (
            <RecoverButton guildID={guildID} itemID={item._id} />
          )}
        </div>
      )}
    </div>
  );
};

export default Item;
