import { useQuery } from "@tanstack/react-query";
import { api } from "@/config/network";
import { useAtomValue } from "jotai";
import { pointsTokenAtom } from "@/stores";

const getDiscordSubscriptions = async (token: string) => {
  return api.get<[] | undefined>(`/admin/discord-subscriptions`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const PremiumLookup = () => {
  const token = useAtomValue(pointsTokenAtom);

  const { data: discordSubscriptions, isLoading } = useQuery({
    queryKey: ["discord-subscriptions"],
    queryFn: async () => {
      if (!token) return undefined;
      const res = await getDiscordSubscriptions(token);
      return res.data;
    },
    enabled: !!token,
  });

  if (isLoading) return <div>Loading...</div>;

  console.log(discordSubscriptions);

  return <></>;
};

export default PremiumLookup;
