import { useParams } from "react-router-dom";
import { GuildParams } from "@/types/routes";
import { Settings } from "@/types/points";
import { useState, useEffect, useCallback } from "react";
import { api } from "@/config/network";
import { useAuthState } from "@/api/useAuthState";
import { useQuery } from "@tanstack/react-query";
import { useGuild } from "./useGuild";
import { get, set } from "lodash";
import { useAtomValue } from "jotai";
import { pointsTokenAtom } from "@/stores";

const useSettings = () => {
  const { guildID } = useParams<GuildParams>();
  const token = useAtomValue(pointsTokenAtom);

  const { data: auth } = useAuthState();
  const { data: guild } = useGuild(guildID);

  const { data, error } = useQuery({
    queryKey: ["settings", guildID],
    queryFn: async (url) => {
      if (!token) return undefined;
      return (
        await api.get<Settings>(`/guilds/${guildID}/settings`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
      ).data;
    },
  });

  const [settings, setSettings] = useState<Settings | undefined>();

  const loading = !data && !error;

  const [errors, setErrors] = useState<string[]>([]);

  const handleSave = useCallback(async () => {
    if (!token) return undefined;
    await api.post(`/guilds/${guildID}/settings`, settings, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    // setSaving(false);
  }, [guildID, settings]);

  useEffect(() => {
    if (data) {
      setSettings(data);
    }
  }, [data]);

  const addError = useCallback(
    (error: string) => {
      setErrors([...errors, error]);
    },
    [errors]
  );

  const removeError = useCallback(
    (error: string) => {
      setErrors(errors.filter((e: string) => e !== error));
    },
    [errors]
  );

  const getSettingValue = useCallback(
    (key: string) => {
      return get(settings, key);
    },
    [settings]
  );

  const setSettingValue = useCallback(
    (key: string, value: any) => {
      const newSettings = { ...settings };
      set(newSettings, key, value);
    },
    [settings]
  );

  return {
    guild,
    settings,
    errors,
    loading,
    getSettingValue,
    setSettingValue,
    addError,
    removeError,
    handleSave,
    setSettings,
    user: auth,
  };
};

export default useSettings;
