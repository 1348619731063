import { SettingField } from "@/config/settings";
import { FC } from "react";
import { GuildPremiumTier } from "@/types/points";

export type SettingCardFunc = ({
  id,
  setting,
}: {
  id: string;
  setting: SettingField;
  premiumTier?: GuildPremiumTier;
}) => React.ReactNode;

type SettingCardProps = SettingField & {
  cardFunc: SettingCardFunc;
  premiumTier?: GuildPremiumTier;
};

const SettingCardWrapper: FC<SettingCardProps> = ({
  cardFunc,
  id,
  premiumTier,
  ...props
}) => {
  return <>{cardFunc({ id, premiumTier, setting: { ...props, id } })}</>;
};

export default SettingCardWrapper;
