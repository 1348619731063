// import Modal from "@/components/Modal";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { cn } from "@/lib/utils";
import { FC, useCallback } from "react";

type ResetPromptProps = {
  type: "all" | "balances" | "backpacks" | "bank";
  onClick: (type: "all" | "balances" | "backpacks" | "bank" | "cancel") => void;
};

const BUTTON_TITLE_MAP = {
  all: "Reset to Default",
  balances: "Reset Balances",
  backpacks: "Reset Backpacks",
  bank: "Reset Bank",
};

const ResetPrompt: FC<ResetPromptProps> = (props) => {
  const { type, onClick } = props;

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button
          onClick={() => onClick(type)}
          className={cn(
            "w-full",
            type === "all"
              ? "bg-red-500"
              : type === "balances"
                ? "bg-blue-500"
                : type === "backpacks"
                  ? "bg-green-500"
                  : "bg-purple-500"
          )}
        >
          {BUTTON_TITLE_MAP[type]}
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {type === "all"
              ? "Reset to Default"
              : type === "balances"
                ? "Reset Server Points"
                : type === "backpacks"
                  ? "Reset Server Backpacks"
                  : "Reset Server Bank"}
          </DialogTitle>
          <DialogDescription>
            You will not be able to retrive the data if you procced.
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button variant="destructive" onClick={() => onClick(type)}>
            {type === "all"
              ? "Reset to Default"
              : type === "balances"
                ? "Reset Server Points"
                : type === "backpacks"
                  ? "Reset Server Backpacks"
                  : "Reset Server Bank"}
          </Button>
          <Button variant="secondary" onClick={() => onClick("cancel")}>
            Cancel
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ResetPrompt;
