import Empty from "@/domain/guild/Empty";
import { useParams } from "react-router-dom";
import Balance from "@/domain/guild/leaderboard/Balance";
import Navbar from "@/components/Navbar";
import GuildHeader from "@/domain/guild/GuildHeader";
import Body from "@/components/Body";
import Footer from "@/components/Footer";
import { useAuthState } from "@/api/useAuthState";
import { useGuild } from "@/api/useGuild";
import { useCurrentMember } from "@/api/useCurrentMember";
import { useLeaderboard } from "@/api/useLeaderboard";
import { useMemo, useState } from "react";
import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination";
import usePagination from "@/hooks/usePagination";

const Leaderboard = () => {
  const { guildID } = useParams<{ guildID: string }>();

  const { data: auth, isLoading: authLoading } = useAuthState();
  const { data: guild, isLoading: guildLoading } = useGuild(guildID);
  const { data: currentMember, isLoading: currentMemberLoading } =
    useCurrentMember(guildID);

  const [currentPage, setCurrentPage] = useState(1);
  const { data: leaderboard, isLoading: leaderboardLoading } = useLeaderboard(
    guildID,
    currentPage,
  );

  const pageCount = useMemo(() => {
    const total = leaderboard?.total || 0;
    return Math.ceil(total / 25);
  }, [leaderboard]);

  const pagination = usePagination({
    total: pageCount,
    currentPage,
  });

  const isLoading =
    authLoading || guildLoading || currentMemberLoading || leaderboardLoading;

  const isEmpty =
    !leaderboard ||
    (leaderboard.balances.length <= 0 && leaderboard.total == 0);

  return (
    <>
      <Navbar />
      <Body>
        <GuildHeader
          guild={guild}
          tab="leaderboard"
          permission={currentMember?.permission}
        />
        {isLoading ? (
          <div className="rounded-xl overflow-hidden shadow-xl bg-primary-foreground border border-border">
            <div className="flex flex-col gap-4 p-6">
              {[...Array(25)].map((_, i) => (
                <div
                  key={i}
                  className="h-10 w-full bg-park-50 dark:bg-park-800 rounded-lg animate-pulse"
                />
              ))}
            </div>
          </div>
        ) : (
          <>
            {isEmpty ? (
              <Empty type="points" />
            ) : (
              <>
                <div className="rounded-xl p-6 gap-4 flex flex-col overflow-hidden shadow-xl bg-primary-foreground border-border border">
                  {leaderboard.balances.map((balance, index) => {
                    const position = index + 1 + (currentPage - 1) * 25;
                    return (
                      <Balance
                        key={balance.userID}
                        num={position}
                        id={auth?._id}
                        userID={balance.userID}
                        currency={guild?.currency}
                        balance={balance.balance}
                        username={
                          balance.user
                            ? `${balance.user?.username}#${balance.user?.discriminator}`
                            : "Unknown User"
                        }
                      />
                    );
                  })}
                </div>

                {pageCount > 1 ? (
                  <Pagination className="mt-4">
                    <PaginationContent>
                      <PaginationItem>
                        <PaginationPrevious
                          onClick={() => setCurrentPage((page) => page - 1)}
                        />
                      </PaginationItem>
                      {pagination.map((page, index) => (
                        <PaginationItem key={`${index}-${page}`}>
                          {typeof page === "number" ? (
                            <PaginationLink
                              onClick={() => setCurrentPage(page)}
                              isActive={currentPage === page}
                            >
                              {page}
                            </PaginationLink>
                          ) : (
                            <PaginationEllipsis />
                          )}
                        </PaginationItem>
                      ))}
                      <PaginationItem>
                        <PaginationNext
                          onClick={() => setCurrentPage((page) => page + 1)}
                        />
                      </PaginationItem>
                    </PaginationContent>
                  </Pagination>
                ) : null}
              </>
            )}
          </>
        )}
      </Body>
      <Footer />
    </>
  );
};

export default Leaderboard;
