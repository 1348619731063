import { useState } from "react";
import Body from "@/components/Body";
import Footer from "@/components/Footer";
import Navbar from "@/components/Navbar";
import commands from "@/config/commands";
import { twMerge } from "tailwind-merge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";

const Commands = () => {
  const [selected, setSelected] = useState<
    | "Bank"
    | "Games"
    | "Items"
    | "Manage Points"
    | "Points"
    | "Rewards"
    | "System"
  >("Points");
  return (
    <>
      <Navbar />
      <Body>
        <h1 className="mt-8 text-4xl font-bold">Commands</h1>
        <div className="flex gap-2 mt-4 mb-6">
          {Object.keys(commands).map((group: any) => {
            return (
              <div
                className={twMerge(
                  "py-1 px-4 rounded-full cursor-pointer text-center text-xs bg-slate-50 dark:bg-slate-700",
                  selected === group &&
                    "bg-brand-500 dark:bg-brand-500 text-white px-3"
                )}
                onClick={() => setSelected(group)}
              >
                {selected === group ? (
                  <FontAwesomeIcon icon={faCheck} className="mr-2" />
                ) : null}
                {group}
              </div>
            );
          })}
        </div>
        <>
          {selected ? (
            // @ts-ignore
            commands[selected].map((command: any) => {
              return (
                <div className="mb-4 shadow-xl p-4 rounded-xl bg-white dark:bg-slate-900 border border-border">
                  <h5 className="font-bold">
                    <strong>/{command.command}</strong>
                    {command.premium && <small> Premium</small>}
                  </h5>
                  <small className="opacity-60">{command.description}</small>
                  <br />
                  <small>
                    <strong>Usage:</strong> /{command.command} {command.usage}
                  </small>
                </div>
              );
            })
          ) : (
            <></>
          )}
        </>
      </Body>
      <Footer />
    </>
  );
};

export default Commands;
