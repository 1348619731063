import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="h-auto py-8 bg-white dark:bg-slate-900/50 border-t border-slate-200 dark:border-slate-900">
      <div className="container mx-auto">
        <p className="text-lg font-bold">Points</p>
        <div className="flex items-center gap-2">
          <Link to="/terms" className="opacity-50">
            Terms of Service
          </Link>
          <p className="opacity-50">|</p>
          <Link to="/privacy" className="opacity-50">
            Privacy Policy
          </Link>
        </div>
        <hr className="my-4 dark:border-slate-800" />
        <p className="text-sm opacity-60">
          {new Date().getFullYear()} © Innatical, LLC
        </p>
      </div>
    </div>
  );
};

export default Footer;
