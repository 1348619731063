import { FC, ReactNode } from "react";
import Footer from "@/components/Footer";
import Loading from "@/components/Loading";
import Navbar from "@/components/Navbar";
import GuildHeader from "../GuildHeader";
import SavePrompt from "./SavePrompt";
import Body from "@/components/Body";
import { APIGuild } from "@/types/points";
import { Group } from "@/config/settings";

type SettingPageProps = {
  guild?: APIGuild;
  groups?: Group[];
  children: ReactNode;
  tab: "commands" | "customization" | "system";
  loading?: boolean;
};

const SettingPage: FC<SettingPageProps> = (props) => {
  const { guild, loading, children } = props;

  return (
    <>
      <Navbar />
      <Body>
        <GuildHeader guild={guild} tab="settings" permission="admin" />
        {loading ? (
          <Loading />
        ) : (
          <>
            <div>{children}</div>
          </>
        )}
      </Body>
      <Footer />
    </>
  );
};

export default SettingPage;
