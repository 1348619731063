import { CSSProperties, FC, ReactNode, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { APIItem, APIRole } from "@/types/points";
import { GuildParams } from "@/types/routes";
import Empty from "../Empty";
import Item, { ItemProps } from "./Item";
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  useDroppable,
} from "@dnd-kit/core";
import {
  useSortable,
  arrayMove,
  SortableContext,
  rectSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useQueryClient } from "@tanstack/react-query";
import { api } from "@/config/network";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripDotsVertical } from "@fortawesome/pro-solid-svg-icons";
import { useAtomValue } from "jotai";
import { pointsTokenAtom } from "@/stores";

type SortableItemProps = {
  id: string;
} & ItemProps;

const SortableItem: FC<SortableItemProps> = (props) => {
  const { id, permission, ...itemProps } = props;

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id,
  });

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    visibility: isDragging ? "hidden" : "visible",
    boxShadow: isDragging ? "0px 0px 10px rgba(0,0,0,0.5)" : "none",
    height: "100%",
  };

  return (
    <div ref={setNodeRef} style={style}>
      <Item
        dragHandle={
          permission === "admin" ? (
            <div style={{ cursor: "grab" }} {...attributes} {...listeners}>
              <FontAwesomeIcon icon={faGripDotsVertical} />
            </div>
          ) : undefined
        }
        permission={permission}
        {...itemProps}
      />
    </div>
  );
};

type DroppableItemProps = {
  id: string;
  children: ReactNode;
};

const DroppableItem: FC<DroppableItemProps> = (props) => {
  const { id, children } = props;

  const { isOver, setNodeRef } = useDroppable({ id });

  const style = {
    // opacity: isOver ? 0.5 : 1,
    // other styles...
  };

  return (
    <div ref={setNodeRef} style={style}>
      {children}
    </div>
  );
};

type ItemGridProps = {
  loading?: boolean;
  items: APIItem[];
  roles: APIRole[];
  permission?: "role" | "admin";
  draggable?: boolean;
};

const ItemGrid: FC<ItemGridProps> = (props) => {
  const { loading, items, permission, roles, draggable } = props;
  const { guildID } = useParams<GuildParams>();
  const queryClient = useQueryClient();
  const token = useAtomValue(pointsTokenAtom);
  const [activeId, setActiveId] = useState<string | null>(null);

  const handleDragEnd = useCallback(async (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const items =
        queryClient.getQueryData<APIItem[]>(["shop", guildID]) ?? [];

      const oldIndex = items.findIndex((item) => item._id === active.id);
      const newIndex = items.findIndex((item) => item._id === over?.id);

      const newItems = arrayMove(items, oldIndex, newIndex);

      const updatedNewItems = newItems.map((item, index) => {
        return {
          ...item,
          priority: index,
        };
      });

      const updatedIds = updatedNewItems.map((item) => item._id);

      queryClient.setQueryData<APIItem[]>(["shop", guildID], updatedNewItems);

      setActiveId(null);

      await api.patch(`/guilds/${guildID}/shop`, updatedIds, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return;
    }

    setActiveId(null);
  }, []);

  const handleDragStart = useCallback((event: DragStartEvent) => {
    setActiveId(event.active.id as string);
  }, []);

  if (loading) {
    return (
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        <div className="w-full h-44 bg-park-50 dark:bg-park-900 rounded-lg animate-pulse" />
        <div className="w-full h-44 bg-park-50 dark:bg-park-900 rounded-lg animate-pulse" />
        <div className="w-full h-44 bg-park-50 dark:bg-park-900 rounded-lg animate-pulse" />
        <div className="w-full h-44 bg-park-50 dark:bg-park-900 rounded-lg animate-pulse" />
        <div className="w-full h-44 bg-park-50 dark:bg-park-900 rounded-lg animate-pulse" />
        <div className="w-full h-44 bg-park-50 dark:bg-park-900 rounded-lg animate-pulse" />
        <div className="w-full h-44 bg-park-50 dark:bg-park-900 rounded-lg animate-pulse" />
        <div className="w-full h-44 bg-park-50 dark:bg-park-900 rounded-lg animate-pulse" />
        <div className="w-full h-44 bg-park-50 dark:bg-park-900 rounded-lg animate-pulse" />
      </div>
    );
  }

  if (!items.length) return <Empty type="items" />;
  if (!guildID) return <></>;

  if (!draggable) {
    return (
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        {items.map((item) => (
          <Item
            key={item._id}
            item={item}
            permission={permission}
            guildID={guildID}
            roles={roles}
            refund
          />
        ))}
      </div>
    );
  }

  return (
    <DndContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
      <SortableContext
        items={items.map((item) => item._id)}
        strategy={rectSortingStrategy}
      >
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          {items.map((item) => (
            <DroppableItem id={item._id} key={item._id}>
              <SortableItem
                id={item._id}
                guildID={guildID}
                permission={permission}
                roles={roles}
                item={item}
                refund
              />
            </DroppableItem>
          ))}
        </div>
      </SortableContext>
      <DragOverlay>
        {activeId ? (
          <SortableItem
            id={activeId}
            item={items.find((item) => item._id === activeId)!}
            permission={permission}
            guildID={guildID}
            roles={roles}
            refund
          />
        ) : null}
      </DragOverlay>
    </DndContext>
  );
};

export default ItemGrid;
