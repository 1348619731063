import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPen, faPlus } from "@fortawesome/pro-solid-svg-icons";
import Empty from "../Empty";
import { useState, FC } from "react";
import { useParams } from "react-router-dom";
import Item from "../shop/Item";
import { useGuild } from "@/api/useGuild";
import { useCurrentMember } from "@/api/useCurrentMember";
import { useShop } from "@/api/useShop";
import { useAtom, useAtomValue } from "jotai";
import { pointsTokenAtom, selectedMemberAtom } from "@/stores";
import { useMember } from "@/api/useMember";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { api } from "@/config/network";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from "@/components/ui/sheet";
import Select from "@/components/Select";
import { Badge } from "@/components/ui/badge";
import { cn } from "@/lib/utils";

type ViewProps = {
  guildID: string;
  memberID: string;
  onViewChange: (view: "OVERVIEW" | "ADD_ITEM" | "MANAGE_BALANCE") => void;
};

const MemberOverview: FC<ViewProps> = (props) => {
  const { guildID, memberID, onViewChange } = props;

  const { data: member, isLoading } = useMember(guildID, memberID);
  const { data: shop } = useShop(guildID);
  const { data: currentMember } = useCurrentMember(guildID);
  const { data: guild } = useGuild(guildID);

  return (
    <>
      <div className="flex items-center justify-between mb-2">
        <h4 className="text-lg font-semibold">Backpack</h4>
        {!!currentMember?.permission ? (
          <button onClick={() => onViewChange("ADD_ITEM")}>
            <FontAwesomeIcon icon={faPlus} size="lg" />
          </button>
        ) : null}
      </div>
      {isLoading ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div className="w-full h-44 bg-park-50 dark:bg-park-900 rounded-lg animate-pulse" />
          <div className="w-full h-44 bg-park-50 dark:bg-park-900 rounded-lg animate-pulse" />
          <div className="w-full h-44 bg-park-50 dark:bg-park-900 rounded-lg animate-pulse" />
          <div className="w-full h-44 bg-park-50 dark:bg-park-900 rounded-lg animate-pulse" />
          <div className="w-full h-44 bg-park-50 dark:bg-park-900 rounded-lg animate-pulse" />
          <div className="w-full h-44 bg-park-50 dark:bg-park-900 rounded-lg animate-pulse" />
        </div>
      ) : (member?.items.length || 0) <= 0 ? (
        <Empty type="backpack" />
      ) : (
        <>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {member?.items.map((backpackItem) => {
              const item = shop?.find((i) => i._id === backpackItem.id);
              if (!item)
                return <div key={backpackItem.id} className="d-none" />;
              return (
                <Item
                  key={item._id}
                  guildID={guildID}
                  preview
                  item={item}
                  permission={currentMember?.permission}
                  roles={guild?.roles || []}
                />
              );
            })}
          </div>
        </>
      )}
    </>
  );
};

const MemberAddItem: FC<ViewProps> = (props) => {
  const { guildID, memberID, onViewChange } = props;

  const [itemSelected, setItemSelected] = useState<string | null>();

  const { data: member } = useMember(guildID, memberID);
  const token = useAtomValue(pointsTokenAtom);
  const queryClient = useQueryClient();
  const { data: shop } = useShop(guildID);

  const addItem = useMutation({
    mutationFn: async ({
      guildID,
      memberID,
      itemID,
    }: {
      guildID: string;
      memberID: string;
      itemID: string;
    }) => {
      if (!token) return;
      const res = await api.post(
        `/guilds/${guildID}/members/${memberID}/${itemID}`,
        undefined,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      return res.data;
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["member", guildID, memberID],
      });
    },
  });

  return (
    <>
      <h3 className="text-lg font-semibold mb-4">Give Item</h3>
      <Select
        className="mb-2"
        onChange={(value) => setItemSelected(value[0])}
        items={
          shop
            ?.filter((item) => {
              const hasItem = member?.items.some((b) => b.id === item._id);
              return !hasItem && !item.expire;
            })
            .map((item) => {
              return {
                value: item._id,
                label: item.name,
              };
            }) ?? []
        }
      />
      <div className="mt-auto flex gap-2 items-center">
        <button
          className="p-2 bg-brand-500 text-white rounded-lg w-full"
          onClick={() => {
            if (!itemSelected || !memberID) return;
            addItem.mutate({
              guildID,
              memberID: memberID,
              itemID: itemSelected,
            });
            onViewChange("OVERVIEW");
          }}
          type="submit"
        >
          Give Item
        </button>
        <button
          className="p-2 bg-park-100 dark:bg-park-700 text-white rounded-lg w-full"
          onClick={() => onViewChange("OVERVIEW")}
        >
          Cancel
        </button>
      </div>
    </>
  );
};

type MemberProps = {
  memberID: string;
};

const Member: FC<MemberProps> = (props) => {
  const { memberID } = props;
  const { guildID } = useParams<{ guildID: string }>();

  const { data: member, isLoading } = useMember(guildID, memberID);
  const { data: currentMember } = useCurrentMember(guildID);

  const [view, setView] = useState<"OVERVIEW" | "ADD_ITEM" | "MANAGE_BALANCE">(
    "OVERVIEW",
  );

  if (!guildID) return null;

  return (
    <>
      <SheetHeader>
        <SheetTitle className="font-semibold text-2xl">
          {isLoading
            ? "Loading..."
            : `${member?.user?.username}${
                member?.user?.discriminator !== "0"
                  ? `#${member?.user?.discriminator}`
                  : ""
              }`}{" "}
        </SheetTitle>
        <SheetDescription>
          {view === "OVERVIEW" ? (
            <div className="gap-2 flex items-center">
              <Badge
                variant="secondary"
                className={cn(
                  "uppercase tracking-wide text-[11px] font-semibold",
                  currentMember?.permission ? "cursor-pointer" : undefined,
                )}
                onClick={() => setView("MANAGE_BALANCE")}
              >
                {member?.balance?.toLocaleString() || 0} points
                {currentMember?.permission ? (
                  <FontAwesomeIcon icon={faPen} className="ml-2" />
                ) : null}
              </Badge>
              <Badge
                variant="secondary"
                className="uppercase tracking-wide text-[11px] font-semibold"
              >
                {member?.items ? `${member.items.length} items` : "0 items"}
              </Badge>
            </div>
          ) : null}
        </SheetDescription>
      </SheetHeader>

      <hr className="border-t border-gray-200 dark:border-slate-800 my-2 mt-0" />
      <div>
        {view === "OVERVIEW" ? (
          <MemberOverview
            guildID={guildID}
            memberID={memberID}
            onViewChange={setView}
          />
        ) : view === "ADD_ITEM" ? (
          <MemberAddItem
            guildID={guildID}
            memberID={memberID}
            onViewChange={setView}
          />
        ) : (
          <>
            <p>Coming soon...</p>
          </>
        )}
      </div>
    </>
  );
};

const MemberModal = () => {
  const [selectedMember, setSelectedMember] = useAtom(selectedMemberAtom);

  return (
    <Sheet
      open={!!selectedMember}
      onOpenChange={(open) => (!open ? setSelectedMember(undefined) : null)}
    >
      <SheetContent className="min-w-[690px] max-w-3xl flex flex-col">
        {selectedMember ? (
          <Member memberID={selectedMember} />
        ) : (
          <SheetHeader>
            <SheetTitle>Loading...</SheetTitle>
            <SheetDescription></SheetDescription>
          </SheetHeader>
        )}
      </SheetContent>
    </Sheet>
  );
};

export default MemberModal;
