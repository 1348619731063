import { Settings } from "@/types/points";

export const commands = {
  daily: {
    enabled: true,
  },
  weekly: {
    enabled: true,
  },
  monthly: {
    enabled: true,
  },
  yearly: {
    enabled: true,
  },
  pay: {
    enabled: true,
  },
  add: {
    enabled: true,
  },
  set: {
    enabled: true,
  },
  subtract: {
    enabled: true,
  },
  create: {
    enabled: true,
  },
  edit: {
    enabled: true,
  },
  delete: {
    enabled: true,
  },
  gift: {
    enabled: true,
  },
  refund: {
    enabled: true,
  },
  buy: {
    enabled: true,
  },
  backpack: {
    enabled: true,
  },
  leaderboard: {
    enabled: true,
  },
  points: {
    enabled: true,
  },
  shop: {
    enabled: true,
  },
  coinflip: {
    enabled: true,
  },
  dice: {
    enabled: true,
  },
  bank: {
    enabled: true,
  },
  tax: {
    enabled: true,
  },
  loan: {
    enabled: true,
  },
  rob: {
    enabled: true,
  },
  take: {
    enabled: true,
  },
  give: {
    enabled: true,
  },
};

// up

export const customization: Settings["customization"] = {
  logs: {
    channel: null,
    webhook_url: null,
  },
  embed: {
    logo: "https://points.bot/images/logo/DiscordPremium.png",
    text: "Points Premium",
    color: "#d9ec37",
  },
  tax: {
    automatic: true,
    sales_rate: 10,
    income_rate: 10,
    due: "weekly",
  },
  loan: {
    interest_rate: 10,
    due: 7,
    max: 0,
  },
  business: {
    enabled: false,
    cost: 100,
    roles: [],
  },
  refund: {
    enabled: true,
    percentage: 100,
  },
  multiplier: 1,
  currency: "points",
  ignore: false,
};

export const premium: Settings["premium"] = {
  enabled: false,
  userID: null,
};

export const system: Settings["system"] = {
  moderators: {
    roles: [],
    members: [],
  },
};

export const defaultSettings: Settings = {
  commands,
  customization,
  premium,
  system,
  rewards: {
    daily: {
      enabled: true,
      min: 100,
      max: 200,
    },
    weekly: {
      enabled: true,
      min: 500,
      max: 1000,
    },
    monthly: {
      enabled: true,
      min: 2000,
      max: 5000,
    },
    yearly: {
      enabled: true,
      min: 10000,
      max: 20000,
    },
    booster: {
      enabled: true,
      amount: 1000,
    },
    message: {
      enabled: true,
      min: 1,
      max: 3,
    },
    joined: {
      enabled: false,
      amount: 0,
    },
  },
};
