import { useQuery } from "@tanstack/react-query";
import { useAtomValue } from "jotai";
import { pointsTokenAtom } from "@/stores";
import { api } from "@/config/network";
import { RESTGetAPIMemberResult } from "@/types/points";

const getCurrentMember = async (token: string, guildID: string) => {
  return api.get<RESTGetAPIMemberResult | undefined>(
    `/guilds/${guildID}/members/@me`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const useCurrentMember = (guildID?: string) => {
  const token = useAtomValue(pointsTokenAtom);

  return useQuery({
    queryKey: ["currentMember", guildID],
    queryFn: async () => {
      if (!token || !guildID) return undefined;
      const res = await getCurrentMember(token, guildID);
      return res.data;
    },
    enabled: !!token && !!guildID,
  });
};
