import { commandsGroups } from "@/config/settings";
import SettingPage from "@/domain/guild/settings/SettingPage";
import useSettings from "@/api/useSettings";
import SettingGroup from "@/domain/guild/settings/SettingGroup";
import { useCallback } from "react";
import SettingTableRow from "@/domain/guild/settings/SettingTableRow";
import { SettingCardFunc } from "@/domain/guild/settings/SettingCardWrapper";
import { useToast } from "@/hooks/use-toast";
import { ToastAction } from "@/components/ui/toast";

const Commands = () => {
  const {
    guild,
    loading,
    getSettingValue,
    setSettingValue,
    addError,
    removeError,
    handleSave,
  } = useSettings();
  const { toast } = useToast();

  const renderSettingsCard: SettingCardFunc = useCallback(
    ({ id, setting }) => {
      const settingValue = getSettingValue(id);
      return (
        <SettingTableRow
          {...setting}
          id={id}
          value={settingValue}
          onChange={(value) => {
            setSettingValue(id, value);
            removeError(id);

            toast({
              duration: Infinity,
              title: "Holy guacamole!",
              description: "Make sure to save your changes!",
              action: (
                <ToastAction
                  altText="Save"
                  onClick={handleSave}
                  className="p-4 bg-brand-500 hover:bg-brand-600"
                >
                  Save
                </ToastAction>
              ),
            });
            // setSaving(true);
          }}
        />
      );
    },
    [addError, getSettingValue, removeError]
  );

  return (
    <SettingPage
      guild={guild}
      loading={loading}
      groups={commandsGroups}
      tab="commands"
    >
      <div className="rounded-xl p-4 overflow-hidden shadow-xl bg-primary-foreground border border-border">
        <p className="font-bold">
          Need more control over who can use those commands?
        </p>
        <p className="mb-2 opacity-70">
          Discord now has a built in permissions system allowing you to go
          indepth with who can use what commands. You can setup the command
          permissions under{" "}
          <strong>Server Settings → Integrations → Points.</strong>
        </p>
        <a
          className="text-brand-500 hover:text-brand-600 transition-colors duration-300 ease-in-out"
          href="https://discord.com/blog/slash-commands-permissions-discord-apps-bots"
        >
          Learn more
        </a>
      </div>
      <div className="rounded-xl mt-4 p-4 w-full overflow-hidden shadow-xl bg-primary-foreground border border-border">
        {commandsGroups.map((group) => (
          <SettingGroup
            {...group}
            key={group.id}
            premiumTier={guild?.premium?.tier}
            tab="commands"
          >
            {renderSettingsCard}
          </SettingGroup>
        ))}
      </div>
    </SettingPage>
  );
};

export default Commands;
