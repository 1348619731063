import Navbar from "@/components/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBolt,
  faBuildingColumns,
  faCards,
  faCartShopping,
  faCashRegister,
  faCogs,
  faCoins,
  faGift,
  faStore,
} from "@fortawesome/pro-solid-svg-icons";
import Footer from "@/components/Footer";
import { useMedia } from "react-use";
import { Button } from "@/components/ui/button";

const features = [
  {
    title: "Dynamic Economy System",
    description:
      "Spark healthy competition and manage wealth with leaderboards, points, and an in-built banking system.",
    icon: faBuildingColumns,
  },
  {
    title: "Interactive Inventory & Shopping",
    description:
      "Explore virtual marketplaces, manage inventories, and share gifts in a vibrant community-driven economy.",
    icon: faCartShopping,
  },
  {
    title: "Exciting Games & Betting",
    description:
      "Engage users with thrilling mini-games like Coinflip, Dice, and Rob for a chance-filled experience.",
    icon: faCards,
  },
  {
    title: "Comprehensive Economy Management",
    description:
      "Empower moderators with robust administrative tools to maintain a balanced and fair virtual economy.",
    icon: faCashRegister,
  },
  {
    title: "Rewarding Loyalty Program",
    description:
      "Keep users engaged with daily rewards and premium benefits on various timescales.",
    icon: faGift,
  },
  {
    title: "Fully Customizable Experience",
    description:
      "Tailor every aspect of Points.bot to fit your community's unique needs and style.",
    icon: faBolt,
  },
];

const featureCards = [
  {
    id: "message-points",
    title: "Message Points",
    icon: faCoins,
    description:
      "Earn 0-3 points per message sent or more with points multiplier. Don't like this? Disable it!",
    image: "/images/other/MessagePointsDefault.png",
  },
  {
    id: "rewards",
    title: "Rewards",
    icon: faCogs,
    description:
      "Need some points? Collect a points everyday by running the /daily command.",
    image: "/images/other/Rewards.png",
  },
  {
    id: "shop",
    title: "Shop",
    icon: faStore,
    description:
      "Using your hard earned points you can buy items that are stocked by your server moderators.",
    image: "/images/other/Shop.png",
  },
];

const Home = () => {
  const isDarkMode = useMedia("(prefers-color-scheme: dark)");
  return (
    <>
      <div
        className="w-full max-h-screen overflow-hidden"
        style={{
          background: isDarkMode
            ? "linear-gradient(180deg, #020617 0%, #28ACFF 99%)"
            : "linear-gradient(180deg, rgba(255, 255, 255, 1) 0%,rgba(235, 252, 255, 1) 100%)",
        }}
      >
        <Navbar />
        <div className="container mx-auto pt-28 flex flex-col">
          <div className="flex flex-col items-center text-center">
            <h1 className="font-extrabold text-5xl mb-8">
              Enriching communities
              <br />
              with economies.
            </h1>
            <h4 className="text-2xl font-light opacity-80 mb-4">
              A fully customizable economy Discord bot offering many
              <br />
              features while remaining as simple and intuitive as possible.
            </h4>
          </div>
          <img
            src="/images/other/DiscordDefault.svg"
            alt="discord"
            className="mt-4 w-full max-h-[400px] object-cover object-top"
          />
        </div>
      </div>
      <div
        className="container flex flex-col mx-auto my-auto items-center justify-center py-16"
        id="features"
      >
        <div>
          <div className="uppercase opacity-50 mb-2 text-center text-xl tracking-widest">
            Building your community
          </div>
          <h3 className="font-bold text-4xl mb-3 text-center">
            Points offers lots of options
            <br />
            to help grow your community.
          </h3>
          <p className="opacity-80 text-center max-w-2xl mx-auto">
            Reward members for being active and participating in your server
            through message points. Host your own economy through our banking
            system. Try your luck with dice and coin flip commands. Give out
            cool rewards with the shop. And so much more.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-12">
            {features.map((feature) => (
              <div
                key={feature.title}
                className="rounded-2xl overflow-hidden shadow-xl bg-primary-foreground border border-border"
              >
                {/* <img
                  src={feature.image}
                  alt={feature.title}
                  className=" w-full h-full max-h-[200px] rounded-t-2xl object-cover object-center"
                /> */}
                <div className="p-6">
                  <FontAwesomeIcon icon={feature.icon} size="3x" />

                  <div className="flex items-center mb-2 mt-4">
                    <h4 className="font-bold text-xl">{feature.title}</h4>
                  </div>
                  <p className="opacity-80">{feature.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="w-full h-[1px] bg-border" />
      <div
        className="container mx-auto py-16 items-center justify-center flex flex-col"
        id="commands"
      >
        <h2 className="mb-12 font-bold text-4xl text-center">
          Essentials Commands
        </h2>
        <div className="flex flex-col gap-4 w-full max-w-xl mx-auto">
          <div className="bg-primary-foreground border border-border rounded-2xl shadow-xl p-4 w-full">
            <h3 className="font-semibold text-lg items-center flex">
              <span className="font-light opacity-50">/</span>points
              <span className="border border-border rounded-lg px-2 ml-2 py-1 text-xs">
                user
              </span>
            </h3>
            <p className="opacity-60">Check your or someone elses balance</p>
          </div>
          <div className="bg-primary-foreground border border-border rounded-2xl shadow-xl p-4 w-full">
            <h3 className="font-semibold text-lg">
              <span className="font-light opacity-50">/</span>shop
              <span className="border border-border rounded-lg px-2 ml-2 py-1 text-xs">
                page
              </span>
            </h3>
            <p className="opacity-60">
              See what’s being sold and buy items from the shop
            </p>
          </div>
          <div className="bg-primary-foreground border border-border rounded-2xl shadow-xl p-4 w-full">
            <h3 className="font-semibold text-lg">
              <span className="font-light opacity-50">/</span>daily
            </h3>
            <p className="opacity-60">
              Get a reward everyday for running this command
            </p>
          </div>
        </div>
        <Button className="mt-8" asChild>
          <a href="/commands">View all commands</a>
        </Button>
      </div>
      <div className="w-full h-[1px] bg-border" />
      <div className="container mx-auto py-16" id="premium">
        <div>
          <h2 className="mb-4 text-center text-4xl">
            Get more customization options
            <br />
            with{" "}
            <strong className="underline underline-offset-2 decoration-2 decoration-premium-500 decoration-wavy">
              Points Premium
            </strong>
          </h2>

          <p className="opacity-50 text-lg text-center">
            Points Premium offers more customization options and
            <br />
            features to help you build your community.
          </p>

          {/* list of features */}
          <ul className="mt-4 flex flex-col gap-2 list-disc list-outside -mr-4 pl-4">
            <li>
              <strong>Enhanced Item Capacity</strong> - Manage a vibrant economy
              with up to 200 items.
            </li>
            <li>
              <strong>Exclusive Rewards System</strong> - Unlock weekly,
              monthly, and yearly reward commands to keep your community
              engaged.
            </li>
            <li>
              <strong>Currency Craft</strong> - Set your unique currency name,
              giving your server its distinct identity. flip.
            </li>
            <li>
              <strong>Balance & Boundaries</strong> - Tailor your economy with
              customized settings: Set reward caps, Starting balances for
              newcomers. Control the economy's flow with custom multipliers.
              with the shop.
            </li>
            <li>
              <strong>Aesthetic Architect</strong> - Style your server's economy
              to your taste by adjusting embed designs.
            </li>
          </ul>

          <Button
            asChild
            className="mt-8 py-3 w-full rounded-xl bg-premium-500 font-bold text-white transition-colors duration-300 ease-in-out hover:bg-premium-600"
          >
            <a
              href={
                "https://discord.com/application-directory/484395597508509697/premium"
              }
            >
              Subscribe on Discord
            </a>
          </Button>
        </div>
      </div>
      <div className="bg-brand-500 w-full">
        <div className="container mx-auto py-16 flex flex-col items-center">
          <h2 className="text-4xl font-bold text-center text-white">
            Ready to get started?
          </h2>
          <p className="text-center text-white opacity-80 max-w-lg mt-4">
            Points is a free to use bot with premium features. You can invite
            the bot to your server and start using it right away.
          </p>
          <Button className="mt-8" size="lg" variant="secondary" asChild>
            <a href="/invite">Add to Discord</a>
          </Button>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;
