import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { GuildPremiumTier } from "../types/points";

export const canUsePremium = (
  groupPremium?: GuildPremiumTier,
  guildPremium?: GuildPremiumTier,
) => {
  if (groupPremium === undefined) return true;
  if (guildPremium === undefined) return false;
  return groupPremium <= guildPremium;
};

export const fromPremiumTierToString = (tier: number) => {
  switch (tier) {
    case 1:
      return "Basic";
    case 2:
      return "Plus";
    case 3:
      return "Ultra";
  }
};

export const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

export const parseAvatar = (
  id?: string,
  avatar?: string | null,
  size: number = 256,
) => {
  if (!id) return undefined;

  if (!avatar)
    return `https://cdn.discordapp.com/embed/avatars/${Number(id) % 5}.png`;

  if (avatar.includes("cdn.discordapp.com")) return avatar;

  const extension = avatar.startsWith("a_") ? "gif" : "png";

  return `https://cdn.discordapp.com/avatars/${id}/${avatar}.${extension}?size=${size}`;
};

export const parseGuildIcon = (
  id?: string,
  icon?: string | null,
  size: number = 256,
) => {
  if (!id) return undefined;
  if (!icon) return undefined;

  if (icon.includes("cdn.discordapp.com")) return icon;

  const extension = icon.startsWith("a_") ? "gif" : "png";

  return `https://cdn.discordapp.com/icons/${id}/${icon}.${extension}?size=${size}`;
};
