import { useQuery } from "@tanstack/react-query";
import Body from "@/components/Body";
import Footer from "@/components/Footer";
import Navbar from "@/components/Navbar";
import docs from "@/config/docs";
import { useMemo } from "react";
import { Link, useMatch } from "react-router-dom";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { twMerge } from "tailwind-merge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";

const docGroups = Object.entries(docs);

const Docs = () => {
  const matchRoute = useMatch("/docs/:group?/:doc?");
  const [_currentGroupId, currentGroup] = useMemo(() => {
    if (!matchRoute || !matchRoute.params.group) {
      return docGroups[0] ?? [null, null];
    }
    return (
      docGroups.find(
        ([groupId, group]) => groupId === matchRoute.params.group
      ) ?? [null, null]
    );
  }, [matchRoute]);

  const [_currentDocId, currentDoc] = useMemo(() => {
    if (!currentGroup) return [null, null];
    if (!matchRoute || !matchRoute.params.doc) {
      return Object.entries(currentGroup.files)[0] ?? [null, null];
    }
    return (
      Object.entries(currentGroup.files).find(
        ([docId, doc]) => docId === matchRoute.params.doc
      ) ?? [null, null]
    );
  }, [currentGroup, matchRoute]);

  const { data, isLoading } = useQuery({
    queryKey: ["docs", currentDoc?.hosted_url],
    queryFn: async () => {
      if (!currentDoc) return;
      const res = await fetch(currentDoc?.hosted_url);
      return res.text();
    },
    enabled: !!currentDoc,
  });

  return (
    <>
      <Navbar />
      <Body>
        <h1 className="mt-8 text-4xl font-bold">Docs</h1>
        <div className="flex gap-2 mt-4 mb-4">
          {docGroups.map(([groupId, group]) => {
            return (
              <Link
                key={groupId}
                to={`/docs/${groupId}`}
                className={twMerge(
                  "py-1 px-4 rounded-full cursor-pointer text-center text-xs bg-border",
                  currentGroup === group &&
                    "bg-brand-500 dark:bg-brand-500 text-white px-3"
                )}
              >
                {currentGroup === group ? (
                  <FontAwesomeIcon icon={faCheck} className="mr-2" />
                ) : null}
                {group.title}
              </Link>
            );
          })}
        </div>
        <div className="flex flex-col gap-6">
          <div className="rounded-xl  w-full bg-primary-foreground p-5 shadow-xl border border-border">
            <h2 className="text-lg font-bold mb-4">Table of Contents</h2>
            <ul className="list-disc pl-4">
              {Object.entries(currentGroup?.files ?? {})?.map(
                ([docId, doc]) => {
                  return (
                    <li key={docId} className="mb-2">
                      <Link
                        to={doc.url}
                        className={twMerge(
                          "hover:underline",
                          currentDoc === doc ? "font-bold" : ""
                        )}
                      >
                        {doc.title}
                      </Link>
                    </li>
                  );
                }
              )}
            </ul>
          </div>
          <div className="w-full overflow-x-hidden text-wrap">
            {isLoading ? (
              <div>Loading...</div>
            ) : (
              <Markdown
                className="prose prose-sm max-w-none prose-neutral dark:prose-invert"
                remarkPlugins={[remarkGfm]}
              >
                {data}
              </Markdown>
            )}
          </div>
        </div>
      </Body>
      <Footer />
    </>
  );
};

export default Docs;
