import { api } from "@/config/network";
import { useAtomValue } from "jotai";
import { FC, useState } from "react";
import { pointsTokenAtom } from "@/stores";
import {
  APIBalance,
  APIBackpack,
  APIReward,
  APIMember,
  APIUser,
  APIPremium,
} from "@/types/points";
import { fromPremiumTierToString, parseAvatar } from "@/utils";
import { Input } from "@/components/ui/input";

type BulkUserBlob = {
  balances: APIBalance[];
  backpacks: APIBackpack[];
  rewards: APIReward[];
  members: APIMember[];
  user?: APIUser;
  premium?: APIPremium;
};

const UserContent: FC<BulkUserBlob> = (props) => {
  const { user, premium, members, balances } = props;
  return (
    <div className="mt-4">
      <div className="flex items-center gap-2">
        <img
          src={parseAvatar(user?._id, user?.avatar, 32)}
          alt="Avatar"
          className="rounded-full"
        />
        <h4 className="font-bold">
          {user?.username}#{user?.discriminator}
        </h4>
      </div>
      <br />
      {!!premium && <p>Premium {fromPremiumTierToString(premium?.tier)}</p>}
      <div>
        <p className="font-bold mb-1">Members</p>
        <table className="w-full">
          <thead>
            <tr>
              <th className="border border-park-100 dark:border-park-700 p-2 text-left">
                Guild ID
              </th>
              <th className="border border-park-100 dark:border-park-700 p-2 text-left">
                Joined At
              </th>
            </tr>
          </thead>
          <tbody>
            {members?.map((member) => (
              <tr>
                <td className="border border-park-100 dark:border-park-700 p-2">
                  {member.guildID}
                </td>
                <td className="border border-park-100 dark:border-park-700 p-2">
                  {member.joinedAt}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <br />
        <p className="font-bold mb-1">Balances</p>
        <table className="w-full">
          <thead>
            <tr>
              <th className="border border-park-100 dark:border-park-700 p-2 text-left">
                Guild ID
              </th>
              <th className="border border-park-100 dark:border-park-700 p-2 text-left">
                Balance
              </th>
              <th className="border border-park-100 dark:border-park-700 p-2 text-left">
                Username
              </th>
            </tr>
          </thead>
          <tbody>
            {balances?.map((balance) => (
              <tr>
                <td className="border border-park-100 dark:border-park-700 p-2">
                  {balance.guildID}
                </td>
                <td className="border border-park-100 dark:border-park-700 p-2">
                  {balance.balance}
                </td>
                <td className="border border-park-100 dark:border-park-700 p-2">
                  {balance.username}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const UserLookup = () => {
  const [editingUserID, setEditingUserID] = useState<string>();

  const [user, setUser] = useState<BulkUserBlob>();
  const token = useAtomValue(pointsTokenAtom);

  return (
    <>
      <form
        className="mt-4"
        onSubmit={async (event) => {
          event.preventDefault();
          if (!editingUserID) return;
          const { data } = await api.get<BulkUserBlob>(
            `/admin/users/${editingUserID}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setUser(data);
        }}
      >
        <Input
          className="w-full p-2 rounded-lg"
          placeholder="User ID"
          value={editingUserID}
          onChange={(event) => setEditingUserID(event.target.value)}
        />
      </form>
      {user && <UserContent {...user} />}
    </>
  );
};

export default UserLookup;
