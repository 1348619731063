import { api } from "@/config/network";
import { atom, useAtom, useAtomValue } from "jotai";
import { FC, useState } from "react";
import { pointsTokenAtom } from "@/stores";
import {
  APIBalance,
  APIBackpack,
  APIReward,
  APIMember,
  APIGuild,
  APIUser,
  APIPremium,
} from "@/types/points";
import { parseAvatar, parseGuildIcon } from "@/utils";
import { Input } from "@/components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { useQuery } from "@tanstack/react-query";

type BulkGuildBlob = {
  balances: APIBalance[];
  backpacks: APIBackpack[];
  rewards: APIReward[];
  members: APIMember[];
  guild?: APIGuild;
};

const GuildContent: FC<BulkGuildBlob> = (props) => {
  const { guild, members, balances } = props;
  return (
    <div className="mt-4">
      <div className="flex items-center gap-2">
        <img
          src={parseGuildIcon(guild?._id, guild?.icon, 32)}
          alt="Avatar"
          className="rounded-full"
        />
        <h4 className="font-bold">{guild?.name}</h4>
      </div>
      <br />
      <div>
        <p className="font-bold mb-1">Members</p>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Guild ID</TableHead>
              <TableHead>Joined At</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {members?.map((member) => (
              <TableRow key={member.userID}>
                <TableCell>{member.userID}</TableCell>
                <TableCell>{member.joinedAt}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <br />
        <p className="font-bold mb-1">Balances</p>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Guild ID</TableHead>
              <TableHead>Balance</TableHead>
              <TableHead>Username</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {balances?.map((balance) => (
              <TableRow key={balance.userID}>
                <TableCell>{balance.userID}</TableCell>
                <TableCell>{balance.balance}</TableCell>
                <TableCell>{balance.username}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

const selectedGuildAtom = atom<string | undefined>(undefined);

const GuildLookup = () => {
  const [selectedGuild, setSelectedGuild] = useAtom(selectedGuildAtom);
  const token = useAtomValue(pointsTokenAtom);

  const { data } = useQuery({
    queryKey: ["admin-guild-lookup", selectedGuild],
    queryFn: async () => {
      if (!selectedGuild) return;
      const { data } = await api.get<BulkGuildBlob>(
        `/admin/guilds/${selectedGuild}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      return data;
    },
    enabled: !!selectedGuild,
  });

  return (
    <>
      <Input
        className="w-full p-2 mt-4 rounded-lg"
        placeholder="Guild ID"
        value={selectedGuild}
        onChange={(event) => setSelectedGuild(event.target.value)}
      />
      {data ? <GuildContent {...data} /> : null}
    </>
  );
};

export default GuildLookup;
