import { z } from "zod";

type FieldType = "info" | "string" | "number" | "textarea" | "select" | "image";

export const itemSchema = z.object({
  name: z.string().min(2).max(30),
  cost: z.coerce.number().min(1).max(1000000000000),
  description: z.string().min(2).max(300),
  amount: z.coerce.number().min(1).max(1000000000000),
  banner: z.string().optional(),
  role: z.string().optional(),
  message: z.string().min(2).max(300).optional(),
  expire: z
    .string()
    .regex(/^$|[1-9][mdhy]$/)
    .optional(),
  supply: z.coerce.number().min(1).max(1000000000000).optional(),
});

export type ItemFieldNames = keyof z.infer<typeof itemSchema>;

export type ItemField = {
  id: ItemFieldNames;
  name: string;
  type: FieldType;
  description: string;
  required?: boolean;
  premium?: boolean;
  schema: z.ZodSchema<any>;
  min?: number;
  max?: number;
  group: "basic" | "perks" | "premium";
};

export const itemFieldGroups: {
  [key: string]: { name: string; description: string };
} = {
  basic: {
    name: "Basic",
    description: "Information about the item",
  },
  perks: {
    name: "Perks",
    description: "Perks awarded when bought",
  },
  premium: {
    name: "Premium",
    description: "Premium exlusive features",
  },
};

const itemFields: ItemField[] = [
  {
    id: "name",
    name: "Item Name",
    type: "string",
    description:
      "This is the item name that will be displayed in the shop and backpacks. You can change this at anytime.",
    schema: z.string().min(2).max(30),
    required: true,
    min: 2,
    max: 30,
    group: "basic",
  },
  {
    id: "cost",
    name: "Item Cost",
    type: "number",
    description:
      "This is the cost of the item. Members must have this amount or greater to buy the item. You can change this at anytime.",
    schema: z.number().min(1).max(1000000000000),
    required: true,
    min: 1,
    max: 1000000000000,
    group: "basic",
  },
  {
    id: "description",
    name: "Item Description",
    type: "textarea",
    description:
      "This is a short description about the item. Include what the item does and any important notes. You can change this at anytime.",
    schema: z.string().min(2).max(300),
    required: true,
    min: 2,
    max: 300,
    group: "basic",
  },
  {
    id: "amount",
    name: "Item Amount",
    type: "number",
    description:
      "This is the how many times members can buy the item. Once they reach the limit, they will only be able to refund the item",
    schema: z.number().min(1).max(1000000000000),
    required: true,
    min: 1,
    max: 1000000000000,
    group: "basic",
  },
  {
    id: "banner",
    name: "Item Banner",
    type: "image",
    description:
      "This is the banner that will be displayed in the shop. You can change this at anytime.",
    schema: z.string().optional(),
    required: false,
    premium: true,
    group: "basic",
  },
  {
    id: "role",
    name: "Item Role",
    type: "select",
    description:
      "This is a role members receive once they buy this item. Make sure the bot can 'Manage Roles' and is above the role. You can change this at anytime. Tip: New roles might take up to 5 min to show up.",
    required: false,
    schema: z.string().optional(),
    group: "perks",
  },
  {
    id: "message",
    name: "Item Message",
    type: "textarea",
    description:
      "This is a message members receive once they buy this item. This can be a thank you note or instructions on how to redeem the item. You can change this at anytime.",
    schema: z.string().min(2).max(300).optional(),
    required: false,
    premium: true,
    min: 2,
    max: 300,
    group: "premium",
  },
  {
    id: "expire",
    name: "Item Expires",
    type: "string",
    description:
      "This is the time the item will expire. When the item expires it will remain in the shop but will be removed from the backpack after the time defined. You can change this at anytime.",
    schema: z
      .string()
      .regex(/^$|[1-9][mdhy]$/)
      .optional(),
    required: false,
    premium: true,
    group: "premium",
  },
  {
    id: "supply",
    name: "Item Supply",
    type: "number",
    description:
      "This is the amount of supply the item has. Once sold out, it can't be bought in the shop but will remain in the backpacks of who bought it. You can change this at anytime.",
    schema: z.number().min(1).max(1000000000000).optional(),
    required: false,
    premium: true,
    group: "premium",
  },
];

export default itemFields;
