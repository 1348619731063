import { FC, useCallback, useMemo, useState } from "react";
import { APIChannel, APIRole } from "discord-api-types/v10";
import { Listbox } from "@headlessui/react";
import { twMerge } from "tailwind-merge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAnglesUpDown,
  faCheck,
  faTimes,
} from "@fortawesome/pro-solid-svg-icons";

type SelectItem = {
  label: string;
  value: string;
};

type SelectProps = {
  className?: string;
  items: SelectItem[];
  disabled?: boolean;
  multiple?: boolean;
  description?: string;
  value?: string[];
  defaultValue?: string[];
  onChange: (value: string[]) => void;
};

const Select: FC<SelectProps> = (props) => {
  const {
    // type,
    className,
    description,
    disabled,
    items,
    multiple,
    value,
    defaultValue,
    onChange,
  } = props;
  const [managedItems, setManagedItems] = useState<string[] | undefined>(
    defaultValue
  );

  const filteredStoreItems = useMemo(() => {
    if (!value) {
      return managedItems?.filter((item) => {
        return items?.find((i) => i.value === item);
      });
    }
    return value?.filter((item) => {
      return items?.find((i) => i.value === item);
    });
  }, [value, managedItems, items]);

  const onInternalChange = useCallback(
    (changedItems: string | string[]) => {
      if (!value) {
        setManagedItems(
          typeof changedItems === "string" ? [changedItems] : changedItems
        );
      }
      onChange(
        typeof changedItems === "string" ? [changedItems] : changedItems
      );
    },
    [value]
  );

  return (
    <div className={twMerge("w-full flex flex-col", className)}>
      {description ? (
        <p className="text-xs mt-1 opacity-70 mb-2">{description}</p>
      ) : null}
      <div className="relative inline-block overflow-visible w-full">
        <Listbox
          disabled={disabled}
          value={filteredStoreItems}
          onChange={onInternalChange}
          multiple={multiple}
        >
          <Listbox.Button className="relative w-full flex items-start flex-wrap gap-2 p-2 rounded-lg border border-border bg-primary-foreground">
            {filteredStoreItems?.length
              ? filteredStoreItems?.map((item) => {
                  return (
                    <span
                      key={item}
                      className="p-1 px-2 rounded-full text-xs bg-secondary"
                    >
                      {items?.find((i) => i.value === item)?.label}

                      <FontAwesomeIcon
                        icon={faTimes}
                        className="ml-2"
                        onClick={(event) => {
                          event.stopPropagation();
                          onInternalChange(
                            filteredStoreItems.filter((i) => i !== item)
                          );
                        }}
                      />
                    </span>
                  );
                })
              : "Select an item"}

            <FontAwesomeIcon
              className="absolute right-3 top-[0.8rem] text-sm opacity-50"
              icon={faAnglesUpDown}
            />
          </Listbox.Button>
          <Listbox.Options className="absolute flex flex-col overflow-y-auto origin-top-right left-0 top-[100%] z-10 w-full p-2 mt-1 bg-primary-foreground rounded-lg border border-border shadow-xl max-h-60">
            {items.map((item) => {
              return (
                <Listbox.Option
                  key={item.value}
                  value={item.value}
                  className="p-2 rounded-lg text-sm hover:bg-park-50 dark:hover:bg-park-800 cursor-pointer"
                >
                  {filteredStoreItems?.includes(item.value) ? (
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="mr-2 opacity-50"
                    />
                  ) : null}
                  {item.label}
                </Listbox.Option>
              );
            })}
          </Listbox.Options>
        </Listbox>
      </div>
    </div>
  );
};

export default Select;
