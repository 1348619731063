import { GuildPremiumTier } from "@/types/points";

export type SubGroup = {
  id: string;
  name?: string;
  tier?: GuildPremiumTier;
};

export type Group = {
  id: string;
  name?: string;
  premium?: GuildPremiumTier;
  sub?: SubGroup[];
};

export type SettingField = {
  id: string;
  name?: string;
  type:
    | "role"
    | "switch"
    | "channel"
    | "number"
    | "string"
    | "color"
    | "image"
    | "group";
  description?: string;
  default?: string | number | boolean | string[];
  group?: string;
  vaildate?: string;
  fields?: SettingField[];
  premium?: GuildPremiumTier;
  error?: string;
};

export const commandsGroups: Group[] = [
  {
    id: "basic_commands",
    name: "Basic Commands",
    sub: [
      {
        id: "manage_points",
        name: "Manage Points",
      },
      {
        id: "manage_items",
        name: "Manage Items",
      },
      {
        id: "points_commands",
        name: "Points Commands",
      },
      {
        id: "items_commands",
        name: "Items Commands",
      },
      {
        id: "bank_commands",
        name: "Bank Commands",
      },
      {
        id: "games_commands",
        name: "Games Commands",
      },
    ],
  },
  {
    id: "premium_commands",
    name: "Premium Commands",
    sub: [
      {
        id: "reward_commands",
        name: "Reward Commands",
      },
    ],
  },
];

export const customizationGroups: Group[] = [
  {
    id: "basic_customization",
    name: "Basic Customization",
  },
  {
    id: "reward_customization",
    name: "Reward Customization",
  },
  {
    id: "embed_customization",
    name: "Embed Customization",
  },
];

export const rewardsFields: SettingField[] = [
  {
    id: "rewards.message",
    type: "group",
    group: "reward_customization",
    fields: [
      {
        id: "rewards.message.enabled",
        name: "Message Reward",
        type: "switch",
        description: "Options for the message reward.",
        default: true,
      },
      {
        id: "rewards.message.min",
        name: "Minimum",
        type: "number",
        default: 0,
      },
      {
        id: "rewards.message.max",
        name: "Maximum",
        type: "number",
        default: 3,
      },
      {
        id: "rewards.message.allowlist",
        name: "Allowlist",
        type: "channel",
        default: [],
      },
      {
        id: "rewards.message.denylist",
        name: "Denylist",
        type: "channel",
        default: [],
      },
    ],
  },
  {
    id: "rewards.joined",
    type: "group",
    group: "reward_customization",
    fields: [
      {
        id: "rewards.joined.enabled",
        name: "Join Reward",
        type: "switch",
        description: "Options for the joined reward.",
        default: false,
      },
      {
        id: "rewards.joined.amount",
        name: "Amount",
        type: "number",
        default: 0,
      },
    ],
  },
  {
    id: "rewards.daily",
    type: "group",
    group: "reward_customization",
    fields: [
      {
        id: "rewards.daily.enabled",
        name: "Daily Reward",
        type: "switch",
        description: "Options for the daily command.",
        default: true,
      },
      {
        id: "rewards.daily.min",
        name: "Minimum",
        type: "number",
        default: 1,
      },
      {
        id: "rewards.daily.max",
        name: "Maximum",
        type: "number",
        default: 100,
      },
    ],
  },
  {
    id: "rewards.weekly",
    type: "group",
    group: "reward_customization",
    premium: GuildPremiumTier.BASIC,
    fields: [
      {
        id: "rewards.weekly.enabled",
        name: "Weekly Reward",
        type: "switch",
        description: "Options for the weekly command.",
        default: true,
      },
      {
        id: "rewards.weekly.min",
        name: "Minimum",
        type: "number",
        default: 10,
      },
      {
        id: "rewards.weekly.max",
        name: "Maximum",
        type: "number",
        default: 1000,
      },
    ],
  },
  {
    id: "rewards.monthly",
    type: "group",
    group: "reward_customization",
    premium: GuildPremiumTier.BASIC,
    fields: [
      {
        id: "rewards.monthly.enabled",
        name: "Monthly Reward",
        type: "switch",
        description: "Options for the monthly command.",
        default: true,
      },
      {
        id: "rewards.monthly.min",
        name: "Minimum",
        type: "number",
        default: 100,
      },
      {
        id: "rewards.monthly.max",
        name: "Maximum",
        type: "number",
        default: 10000,
      },
    ],
  },
  {
    id: "rewards.yearly",
    type: "group",
    group: "reward_customization",
    premium: GuildPremiumTier.PLUS,
    fields: [
      {
        id: "rewards.yearly.enabled",
        name: "Yearly Reward",
        type: "switch",
        description: "Options for the yearly command.",
        default: true,
      },
      {
        id: "rewards.yearly.min",
        name: "Minimum",
        type: "number",
        default: 1000,
      },
      {
        id: "rewards.yearly.max",
        name: "Maximum",
        type: "number",
        default: 100000,
      },
    ],
  },
];

export const customizationFields: SettingField[] = [
  {
    id: "system.moderators.roles",
    name: "Moderators",
    type: "role",
    group: "basic_customization",
    description: "Gives access to moderator commands for people without admin.",
    default: [],
  },
  {
    id: "customization.refund",
    type: "group",
    group: "basic_customization",
    fields: [
      {
        id: "customization.refund.enabled",
        name: "Item Refund",
        type: "switch",
        description: "Allow members to refund items.",
        default: true,
      },
      {
        id: "customization.refund.percentage",
        name: "Percentage",
        type: "number",
        default: 100,
        vaildate: "^[5-9]0|100?$",
        error: "Amount must be between 50-100%",
      },
    ],
  },
  {
    id: "customization.logs.webhook_url",
    name: "Logs",
    type: "string",
    description: "Log dashboard item changes to a webhook URL.",
    group: "basic_customization",
    default: "",
    vaildate:
      "^(https?://)((canary.|ptb.)?discord(app)?.com)(/api/webhooks/).*$",
    error: "Must be a vaild discord webhook URL",
  },
  {
    id: "system.moderators.roles",
    name: "Moderators",
    type: "role",
    default: "",
    description: "Gives access to moderator commands for people without admin.",
    group: "general",
  },
  {
    id: "customization.tax",
    type: "group",
    group: "basic_customization",
    fields: [
      {
        id: "customization.tax.automatic",
        name: "Income Tax",
        type: "switch",
        description:
          "If enabled tax will be collected automatically from income.",
        group: "basic_customization",
        default: true,
      },
      {
        id: "customization.tax.income_rate",
        name: "Tax Rate",
        type: "number",
        group: "basic_customization",
        default: 10,
      },
    ],
  },
  {
    id: "customization.currency",
    name: "Currency Name",
    type: "string",
    premium: GuildPremiumTier.BASIC,
    description:
      "Set the currency name. Make sure that it is plural and is not capitalized for the best result.",
    group: "basic_customization",
    default: "points",
    vaildate: "^[\\w\\W]{1,16}$",
    error: "Text must be between 1-16 characters long.",
  },
  {
    id: "customization.embed.color",
    name: "Color",
    type: "color",
    premium: GuildPremiumTier.PLUS,
    description:
      "Custom embed color. Make sure that it is a hex code or else it won't work.",
    group: "embed_customization",
    default: "#f0ab00",
  },
  {
    id: "customization.embed.logo",
    name: "Logo",
    type: "image",
    description: "Custom embed footer logo.",
    premium: GuildPremiumTier.PLUS,
    group: "embed_customization",
    default: "https://points.bot/images/logo/DiscordPremium.png",
    vaildate:
      "^(https?://)(((b.)?file.coffee/)|(points.bot)).*.(?:jpg|jpeg|png)$",
    error: "Logo must be a file.coffee png or jpg",
  },
  {
    id: "customization.embed.text",
    name: "Text",
    type: "string",
    premium: GuildPremiumTier.PLUS,
    description:
      "Custom embed footer text. Must be between 2-16 characters long.",
    group: "embed_customization",
    default: "Points Premium",
    vaildate: "^[\\w\\W]{1,16}$",
    error: "Text must be between 1-16 characters long.",
  },
  ...rewardsFields,
];

export const commandsFields: SettingField[] = [
  {
    id: "commands.add.enabled",
    name: "Add",
    type: "switch",
    description: "Moderators can add points to members.",
    group: "manage_points",
    default: true,
  },
  {
    id: "commands.set.enabled",
    name: "Set",
    type: "switch",
    description: "Moderators can set points for members.",
    group: "manage_points",
    default: true,
  },
  {
    id: "commands.subtract.enabled",
    name: "Subtract",
    type: "switch",
    description: "Moderators can subtract points from members.",
    group: "manage_points",
    default: true,
  },
  {
    id: "commands.take.enabled",
    name: "Take",
    type: "switch",
    description: "Moderators can take items from members.",
    group: "manage_items",
    default: true,
  },
  {
    id: "commands.give.enabled",
    name: "Give",
    type: "switch",
    description: "Moderators can give items to members.",
    group: "manage_items",
    default: true,
  },
  {
    id: "commands.create.enabled",
    name: "Create",
    type: "switch",
    description: "Moderators can create items for the shop.",
    group: "manage_items",
    default: true,
  },
  {
    id: "commands.edit.enabled",
    name: "Edit",
    type: "switch",
    description: "Moderators can edit items in the shop.",
    group: "manage_items",
    default: true,
  },
  {
    id: "commands.delete.enabled",
    name: "Delete",
    type: "switch",
    description: "Moderators can delete items from the shop.",
    group: "manage_items",
    default: true,
  },
  {
    id: "commands.points.enabled",
    name: "Points",
    type: "switch",
    description:
      "Display's members current balance. Premium members can mention roles and up to 5 members.",
    group: "points_commands",
    default: true,
  },
  {
    id: "commands.leaderboard.enabled",
    name: "Leaderboard",
    type: "switch",
    description:
      "Display's top 10 balances. Premium members can mention roles.",
    group: "points_commands",
    default: true,
  },
  {
    id: "commands.daily.enabled",
    name: "Daily",
    type: "switch",
    description:
      "Members get a daily reward. Premium members get double points.",
    group: "points_commands",
  },
  {
    id: "commands.pay.enabled",
    name: "Pay",
    type: "switch",
    description: "Members are able to pay each other using their own points.",
    group: "points_commands",
    default: true,
  },
  {
    id: "commands.buy.enabled",
    name: "Buy",
    type: "switch",
    description: "Display's servers items.",
    group: "items_commands",
    default: true,
  },
  {
    id: "commands.refund.enabled",
    name: "Refund",
    type: "switch",
    description: "Allows members to refund items.",
    group: "items_commands",
    default: true,
  },
  {
    id: "commands.gift.enabled",
    name: "Gift",
    type: "switch",
    description: "Allows members to gift each other items.",
    group: "items_commands",
    default: true,
  },
  {
    id: "commands.backpack.enabled",
    name: "Backpack",
    type: "switch",
    description: "Display's members owned items.",
    group: "items_commands",
    default: true,
  },
  {
    id: "commands.bank.enabled",
    name: "Bank",
    type: "switch",
    description: "Members can check the banks balance.",
    group: "bank_commands",
    default: true,
  },
  {
    id: "commands.tax.enabled",
    name: "Tax",
    type: "switch",
    description: "Allow moderators to tax other members.",
    group: "bank_commands",
    default: true,
  },
  {
    id: "commands.coinflip.enabled",
    name: "Coin Flip",
    type: "switch",
    description: "Members can flip a coin with a bet.",
    group: "games_commands",
    default: true,
  },
  {
    id: "commands.dice.enabled",
    name: "Dice",
    type: "switch",
    description: "Members can roll a dice with a bet.",
    group: "games_commands",
    default: true,
  },
  {
    id: "commands.rob.enabled",
    name: "Rob",
    type: "switch",
    description: "Members can rob other members.",
    group: "games_commands",
    default: true,
  },
  {
    id: "commands.weekly.enabled",
    name: "Weekly",
    type: "switch",
    description: "Members get a weekly reward.",
    group: "reward_commands",
    default: true,
  },
  {
    id: "commands.monthly.enabled",
    name: "Monthly",
    type: "switch",
    description: "Members get a monthly reward.",
    group: "reward_commands",
    default: true,
  },
  {
    id: "commands.yearly.enabled",
    name: "Yearly",
    type: "switch",
    description: "Members get a yearly reward.",
    group: "reward_commands",
    default: true,
  },
];
