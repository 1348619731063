import { atom } from "jotai";
import { APIItem } from "./types/points";
import { atomWithStorage } from "jotai/utils";

export const selectedMemberAtom = atom<string | undefined>(undefined);
export const pointsTokenAtom = atomWithStorage<string | null>(
  "ptoken",
  null,
  undefined,
  {
    getOnInit: true,
  }
);
// added token here
