import { selectedMemberAtom } from "@/stores";
import { faEllipsisH } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSetAtom } from "jotai";
import { FC } from "react";
import { twMerge } from "tailwind-merge";

type BalanceProps = {
  num: number;
  id?: string;
  userID: string;
  balance: number;
  currency?: string;
  username?: string;
};

const abbr = Intl.NumberFormat("en-US", {
  notation: "compact",
  maximumFractionDigits: 1,
});

const Balance: FC<BalanceProps> = (props) => {
  const { num, userID, balance, username, currency } = props;

  const setSelectedMember = useSetAtom(selectedMemberAtom);
  const displayName = username
    ? username.replace("#0", "")
    : `Unknown User: ${userID}`;

  const displayBalance =
    balance === Infinity || balance > 1000000000000
      ? "♾️"
      : abbr.format(Math.round(balance));

  return (
    <div className="flex items-center justify-between flex-nowrap">
      <div className="flex items-center overflow-hidden gap-4 flex-nowrap">
        <div
          className={twMerge(
            "w-10 h-10 rounded-full dark:text-white flex items-center justify-center font-bold",
            num === 1 && "text-white bg-premium-500",
            num === 2 && "text-white bg-neutral-500",
            num > 2 && "bg-slate-200 dark:bg-secondary",
          )}
        >
          {num}
        </div>
        <p className="truncate opacity-70">{displayName}</p>
      </div>
      <div className="flex items-center gap-2 flex-nowrap">
        <div className="flex items-center gap-4 bg-slate-200 dark:bg-secondary rounded-full px-2 py-1 text-xs font-semibold uppercase tracking-wider">
          {displayBalance} {currency || "points"}
        </div>
        <button
          className="hover:bg-slate-200 dark:hover:bg-secondary hover:shadow-xl rounded-full w-6 h-6 flex items-center justify-center"
          onClick={() => setSelectedMember(userID)}
        >
          <FontAwesomeIcon icon={faEllipsisH} />
        </button>
      </div>
    </div>
  );
};

export default Balance;
