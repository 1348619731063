import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWallet,
  faBoxOpen,
  faDoorOpen,
} from "@fortawesome/pro-solid-svg-icons";
import { FC } from "react";

interface EmptyProps {
  type?: "points" | "items" | "backpack";
}

const emptyStates = {
  points: {
    icon: faWallet,
    title: "This server is empty.",
    description: "There are no people on this server with any points.",
  },
  items: {
    icon: faBoxOpen,
    title: "There's nothing to buy.",
    description: "Ask your server admins to stock these shelves with items.",
  },
  backpack: {
    icon: faBoxOpen,
    title: "This backpack is empty.",
    description: "There are no item's in this backpack.",
  },
};

const Empty: FC<EmptyProps> = ({ type }) => {
  const state = type ? emptyStates[type] : undefined;
  return (
    <div className="flex flex-col p-6 items-center justify-center rounded-xl shadow-xl bg-primary-foreground border border-border">
      <FontAwesomeIcon icon={state?.icon || faDoorOpen} size="5x" />
      <h4 className="text-lg font-semibold mt-4">
        {state?.title || "No servers found."}
      </h4>
      <p className="text-xs mt-1 opacity-70">
        {state?.description || "You aren't in any servers."}
      </p>
    </div>
  );
};

export default Empty;
