import { FC, useEffect } from "react";
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import Body from "@/components/Body";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons";

type RedirectProps = {
  url?: string;
};

const Redirect: FC<RedirectProps> = (props) => {
  const { url } = props;

  useEffect(() => {
    if (!url) return;
    const timeout = setTimeout(() => {
      window.location.href = url;
    }, 3000);

    return () => clearTimeout(timeout);
  }, [url]);

  return (
    <>
      <Navbar />
      <Body className="flex flex-col  justify-center">
        <div className="flex items-center">
          <h1 className="text-4xl font-bold">
            <FontAwesomeIcon
              icon={faSpinnerThird}
              className="mr-4 animate-spin"
            />
            Redirecting...
          </h1>
        </div>
        <p className="mt-3">
          You are being redirected.{" "}
          <a href={url} className="text-brand-500 cursor-pointer">
            Click this if you aren't redirected in 5 seconds.
          </a>
        </p>
      </Body>
      <Footer />
    </>
  );
};

export default Redirect;
