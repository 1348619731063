import { FC } from "react";
import Loading from "./Loading";
import { useAuthState } from "@/api/useAuthState";
import { API_URL } from "@/config/network";

const Redirect = () => {
  sessionStorage.setItem("redirect", window.location.pathname);
  window.location.href = `${API_URL}/auth/login`;
  return <></>;
};

type RequireAuthProps = {
  children: React.ReactNode;
  permission?: "support" | "admin" | "owner";
  premium?: boolean;
};

const RequireAuth: FC<RequireAuthProps> = (props) => {
  const { children, permission, premium } = props;
  const { data: auth, isLoading } = useAuthState();
  if (isLoading) return <Loading />;
  if (!auth) return <Redirect />;
  if (permission && auth.permission !== permission) return <></>;
  if (premium && !auth?.premium?.tier) return <></>;
  return <>{children}</>;
};

export default RequireAuth;
