import { useMutation, useQueryClient } from "@tanstack/react-query";
import { api } from "@/config/network";
import { useAtomValue } from "jotai";
import { FC } from "react";
import { pointsTokenAtom } from "@/stores";
import { Button } from "@/components/ui/button";

type BuyProps = {
  memberID?: string;
  guildID: string;
  itemID: string;
  insufficientFunds: boolean;
  soldOut: boolean;
  cost?: number;
};

const BuyButton: FC<BuyProps> = (props) => {
  const { memberID, guildID, itemID, insufficientFunds, soldOut, cost } = props;

  const token = useAtomValue(pointsTokenAtom);
  const queryClient = useQueryClient();
  const buyItem = useMutation({
    mutationFn: async ({
      guildID,
      itemID,
    }: {
      guildID: string;
      itemID: string;
    }) => {
      if (!token) return;
      const res = await api.get(`/guilds/${guildID}/shop/${itemID}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return res.data;
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["currentMember", guildID],
      });
      queryClient.invalidateQueries({
        queryKey: ["shop", guildID],
      });
    },
  });

  const buttonText = soldOut
    ? "Sold Out"
    : insufficientFunds
      ? "Insufficient Funds"
      : `Buy for ${cost} points`;

  return (
    <Button
      className="w-full"
      disabled={insufficientFunds || soldOut}
      onClick={() => {
        if (!insufficientFunds && !soldOut && memberID) {
          buyItem.mutate({ guildID, itemID });
        }
      }}
    >
      {buyItem.isPending ? "Buying..." : buttonText}
    </Button>
  );
};

export default BuyButton;
