import { FC } from "react";
import { Link } from "react-router-dom";
import { APIUserGuild } from "@/types/points";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins } from "@fortawesome/pro-solid-svg-icons";

type GuildProps = APIUserGuild & {};

const Guild: FC<GuildProps> = (props) => {
  const { _id, name, icon, premium, invited } = props;

  return (
    <Link
      className="rounded-xl overflow-hidden shadow-xl bg-primary-foreground border border-border transition-colors duration-300 ease-in-out hover:bg-slate-50 dark:hover:bg-slate-800 hover:border-slate-200 dark:hover:border-slate-700 p-4"
      to={invited ? `/guilds/${_id}` : `/invite/${_id}`}
    >
      <div className="overflow-hidden flex min-w-0">
        {icon ? (
          <img
            className="w-16 h-16 rounded-lg object-cover object-center"
            draggable="false"
            src={
              icon
                ? `https://cdn.discordapp.com/icons/${_id}/${icon}.png`
                : undefined
            }
            alt={name}
          />
        ) : (
          <div className="w-16 h-16 flex text-xl font-bold items-center justify-center rounded-lg bg-slate-200 dark:bg-slate-500">
            {name?.at(0) ?? ""}
          </div>
        )}
        <div className="ml-4 my-auto overflow-hidden">
          <div className="items-center flex">
            <p className="truncate text-lg opacity-70">{name}</p>
          </div>
          {premium?.tier ? (
            <div className="min-w-0 flex items-center gap-2">
              <FontAwesomeIcon icon={faCoins} className="text-premium-500" />
              <span className="text-premium-500">Premium</span>
            </div>
          ) : null}
        </div>
      </div>
    </Link>
  );
};

export default Guild;
