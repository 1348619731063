import { customizationGroups } from "@/config/settings";
import SettingPage from "@/domain/guild/settings/SettingPage";
import useSettings from "@/api/useSettings";
import SettingGroup from "@/domain/guild/settings/SettingGroup";
import SettingCard from "@/domain/guild/settings/SettingCard";
import { GuildPremiumTier } from "@/types/points";
import { useCallback, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRemove } from "@fortawesome/pro-solid-svg-icons";
import { api } from "@/config/network";
import { useAuthState } from "@/api/useAuthState";
import { useGuild } from "@/api/useGuild";
import { GuildParams } from "@/types/routes";
import { Link, useParams } from "react-router-dom";
import { defaultSettings } from "@/config/defaults";
import ResetPrompt from "@/domain/guild/settings/ResetPrompt";
import { useQueryClient } from "@tanstack/react-query";
import { useAtomValue } from "jotai";
import { pointsTokenAtom } from "@/stores";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { useToast } from "@/hooks/use-toast";
import { ToastAction } from "@/components/ui/toast";

type ManagePremiumModalProps = {
  guildID?: string;
  premiumTier?: GuildPremiumTier;
};

const ManagePremiumModal = (props: ManagePremiumModalProps) => {
  const { guildID, premiumTier } = props;
  const { data: auth } = useAuthState();
  const token = useAtomValue(pointsTokenAtom);

  const toggleGuild = useCallback(async (id: string) => {
    if (!id) return;
    if (!token) return;
    await api.post(`/guilds/${id}/settings/premium`, undefined, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    window.location.reload();
  }, []);

  if (premiumTier === GuildPremiumTier.BASIC) {
    return (
      <div>
        This servers Premium Basic is enabled by default because you own this
        server...
      </div>
    );
  }

  const isInGuilds = auth?.premium?.guilds?.find((g) => g === guildID);

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button className="p-2 px-4 bg-premium-500 hover:bg-premium-600 text-white">
          Manage Premium
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Active Premium Servers</DialogTitle>
          <DialogDescription>
            Manage which servers have Premium enabled.
          </DialogDescription>
        </DialogHeader>
        <div>
          {auth?.premium?.guilds?.map((guildID) => {
            const guild = auth?.guilds?.find((g) => g._id === guildID);
            return (
              <div
                key={guildID}
                className="rounded-xl p-4 mb-4 flex items-center justify-between bg-dark"
              >
                <p>{guild?.name}</p>

                <button
                  className="p-2 bg-red-500 text-white font-bold rounded-lg"
                  onClick={() => toggleGuild(guildID)}
                >
                  <FontAwesomeIcon icon={faRemove} />
                </button>
              </div>
            );
          })}
        </div>
        {!isInGuilds && (
          <div>
            <h6 className="opacity-70 mb-4">
              Looks like we couldn't find this server in your active servers.
              You can still disable it here...
            </h6>
            <DialogFooter>
              <Button
                variant="destructive"
                onClick={() => toggleGuild(guildID!)}
              >
                Disable Server Premium
              </Button>
            </DialogFooter>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

const PremiumCard = () => {
  const { guildID } = useParams<GuildParams>();
  const { data: auth } = useAuthState();
  const { data: guild } = useGuild(guildID);
  const token = useAtomValue(pointsTokenAtom);

  const tier = guild?.premium?.tier;
  const source = guild?.premium?.source;
  const userID = guild?.premium?.userID;

  const togglePremium = useCallback(async () => {
    if (!guild?._id) return;
    if (!token) return;
    await api.post(`/guilds/${guild._id}/settings/premium`, undefined, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    window.location.reload();
  }, [guild]);

  if (!tier && !!auth?.premium?.tier) {
    if (auth?.premium?.tier === 1) {
      return (
        <p className="opacity-70">
          You need to own the server to use premium customizations. Subscribe to
          a higher tier to unlock customizations in servers you don't own.
        </p>
      );
    }
    if (auth?.premium?.availableGuildSlots === 0) {
      return (
        <>
          <div>
            <p className="opacity-70">
              You have no available server slots. Consider upgrading your
              subscription to add more.
            </p>
            <ManagePremiumModal guildID={guild?._id} premiumTier={tier} />
          </div>
        </>
      );
    }
    return (
      <div>
        <div className="flex items-center gap-4">
          <h4>Premium</h4>
          <div className="bg-premium-500 text-xs text-white font-bold rounded-full p-1 px-2">
            {auth?.premium?.tier === 2 ? "Plus" : "Ultra"}
          </div>
        </div>
        <div className="flex items-center justify-between mt-1">
          <p className="opacity-70">
            You can enable Premium{" "}
            {auth?.premium?.tier === 2 ? "Plus" : "Ultra"} to allow all server
            members to use Premium for this server.
          </p>
          <button
            className="p-2 px-4 bg-premium-500 text-white font-bold rounded-lg"
            onClick={() => togglePremium()}
          >
            Enable Premium
          </button>
        </div>
      </div>
    );
  }

  if (tier === GuildPremiumTier.PLUS && userID === auth?._id) {
    return (
      <>
        <div>
          <div className="flex items-center gap-2">
            <h4 className="font-bold">Premium</h4>
            <div className="bg-premium-500 text-xs text-white font-bold rounded-full p-1 px-2">
              Plus
            </div>
          </div>
          <div className="flex items-center justify-between mt-1">
            <p className="opacity-70">
              You have enabled Premium Plus for this server.
            </p>
            <ManagePremiumModal guildID={guild?._id} premiumTier={tier} />
          </div>
        </div>
      </>
    );
  }

  if (tier === GuildPremiumTier.BASIC && guild?.ownerID === auth?._id) {
    const canAddMorePremium =
      (auth?.premium?.tier || 0) > 1 &&
      (auth?.premium?.availableGuildSlots || 0) > 0;
    return (
      <div>
        <div className="flex items-center gap-4">
          <h4 className="font-bold">Premium</h4>
          <div className="bg-premium-500 text-xs text-white font-bold rounded-full p-1 px-2">
            Basic
          </div>
        </div>
        <div className="mt-1">
          <div className="opacity-70">
            Premium Basic was automatically enabled for this server as part of
            your subscription.{" "}
            {!canAddMorePremium && (
              <strong>
                Only you can use Premium Basic features.{" "}
                <a href="https://www.patreon.com/join/PointsCity/checkout?rid=3790562">
                  Upgrade to add more Premium Plus server slots.
                </a>
              </strong>
            )}
          </div>
          {canAddMorePremium && (
            <div className="mt-4 flex items-center justify-between">
              <p className="opacity-70">
                You can enable Premium Plus to allow all server members to use
                Premium for this server.
              </p>
              <button
                className="p-2 bg-brand-500 text-white rounded-lg"
                onClick={() => togglePremium()}
              >
                Enable Premium
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }

  if (!!tier && userID !== auth?._id) {
    if (source === "discord") {
      // tell user that premium is enabled in discord
      return (
        <div>
          <p className="opacity-70">
            Premium is enabled for this server via Discord. You can manage your
            subscription in Discord.
          </p>
        </div>
      );
    } else {
      return (
        <div>
          <p className="opacity-70">
            Premium is enabled for this server via Patreon. You can manage your
            subscription in Patreon.
          </p>
        </div>
      );
    }
  }

  return (
    <div>
      <h4 className="font-bold">Get Premium</h4>
      <p className="opacity-70">
        Looks like you don't have Premium. Premium enables more customization to
        give you more control over your server setup. Own Premium but
        subscription hasn't actived? Contact us{" "}
        <a className="text-brand-500" href="https://discord.gg/innatical">
          discord.gg/innatical
        </a>
      </p>
    </div>
  );
};

const Customization = () => {
  const {
    guild,
    loading,
    settings,
    handleSave,
    addError,
    getSettingValue,
    setSettings,
    setSettingValue,
    removeError,
  } = useSettings();
  const queryClient = useQueryClient();
  const token = useAtomValue(pointsTokenAtom);
  const { toast } = useToast();

  const handleReset = useCallback(
    async (type: "all" | "balances" | "backpacks" | "bank" | "cancel") => {
      if (!guild) return;
      if (!token) return;
      await api.post(
        `/guilds/${guild?._id}/settings/reset`,
        {
          type,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSettings(defaultSettings);
    },
    [guild, setSettings]
  );

  const handleResetAPIKey = useCallback(async () => {
    if (!guild) return;
    if (!token) return;
    await api.post(
      `/guilds/${guild?._id}/settings/reset-api-key`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    queryClient.invalidateQueries({
      queryKey: ["settings", guild?._id],
    });
  }, [guild, queryClient]);

  const hasOldCommands = useMemo(() => {
    return Object.values(settings?.commands ?? {})
      .map((value) => {
        if (!value) return false;
        if (value.enabled === false) return true;
        return false;
      })
      .includes(true);
  }, [settings?.commands]);

  return (
    <>
      <SettingPage
        guild={guild}
        loading={loading}
        tab="customization"
        groups={customizationGroups}
      >
        <div className="rounded-xl p-4 overflow-hidden shadow-xl bg-primary-foreground border border-border">
          <p className="font-bold">
            Need more control over who can use those commands?
          </p>
          <p className="mb-2 opacity-70">
            Discord now has a built in permissions system allowing you to go
            indepth with who can use what commands. You can setup the command
            permissions under{" "}
            <strong>Server Settings → Integrations → Points.</strong>
          </p>
          {hasOldCommands ? (
            <>
              <p className="mb-2 opacity-70">
                You have old command permissions setup. You can manage them
                here. This will be deprecated soon so please move to the above
                soon.
              </p>
              <Button asChild className="w-full">
                <Link to={`/guilds/${guild?._id}/settings/commands`}>
                  Manage Command Permissions
                </Link>
              </Button>
            </>
          ) : (
            <a
              className="text-brand-500 hover:text-brand-600 transition-colors duration-300 ease-in-out"
              href="https://discord.com/blog/slash-commands-permissions-discord-apps-bots"
            >
              Learn more
            </a>
          )}
        </div>
        <div className="rounded-xl mt-4 p-4 overflow-hidden shadow-xl bg-primary-foreground border border-border">
          <PremiumCard />
        </div>
        {customizationGroups.map((group) => (
          <SettingGroup
            {...group}
            key={group.id}
            premiumTier={guild?.premium?.tier}
            tab="customization"
          >
            {({ id, setting, premiumTier }) => {
              const settingValue = getSettingValue(id);
              return (
                <SettingCard
                  {...setting}
                  id={id}
                  value={settingValue}
                  onError={() => addError(id)}
                  items={
                    setting.type === "role" ? guild?.roles : guild?.channels
                  }
                  premiumTier={premiumTier}
                  onChange={(value, changedID) => {
                    if (changedID) {
                      setSettingValue(changedID, value);
                      removeError(changedID);
                    } else {
                      setSettingValue(id, value);
                      removeError(id);
                    }
                    toast({
                      duration: Infinity,
                      title: "Holy guacamole!",
                      description: "Make sure to save your changes!",
                      action: (
                        <ToastAction
                          altText="Save"
                          onClick={handleSave}
                          className="p-4 bg-brand-500 hover:bg-brand-600"
                        >
                          Save
                        </ToastAction>
                      ),
                    });
                  }}
                />
              );
            }}
          </SettingGroup>
        ))}
        <hr className="my-8 border border-park-100 dark:border-park-800" />
        <div className="rounded-xl relative p-4 shadow-xl bg-primary-foreground border border-premium-500">
          <h5 className="font-bold">
            API Key
            <div className="uppercase tracking-wider font-semibold flex items-center justify-center rounded-full px-2 py-1 text-xs absolute -top-2.5 -right-2.5 shadow-xl bg-premium-500 text-white">
              Ultra
            </div>
          </h5>
          <p className="opacity-70 mb-2">
            You can use the Points API to interact with your server. You can
            generate an API key here. This feature is currently in closed beta.
          </p>
          <div className="flex gap-2">
            <input
              type="text"
              className="w-full p-2 rounded-lg border border-border bg-primary-foreground"
              placeholder="No API Key Set"
              readOnly
              value={settings?.apiKey}
            />
            <button
              className="p-2 px-4 text-nowrap rounded-lg bg-red-400 disabled:bg-park-600 text-white"
              disabled={!settings?.apiKey}
              onClick={handleResetAPIKey}
            >
              Reset
            </button>
          </div>
        </div>
        <hr className="my-8 border border-park-100 dark:border-park-800" />
        <div className="rounded-xl p-4 overflow-hidden shadow-xl bg-red-50/10 dark:bg-red-900/10 border border-red-100 dark:border-red-700">
          <h5 className="mb-2 font-bold">Danger Zone</h5>
          <div className="flex gap-4">
            <ResetPrompt type="bank" onClick={handleReset} />
            <ResetPrompt type="backpacks" onClick={handleReset} />
            <ResetPrompt type="balances" onClick={handleReset} />
            <ResetPrompt type="all" onClick={handleReset} />
          </div>
        </div>
      </SettingPage>
    </>
  );
};

export default Customization;
