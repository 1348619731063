import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import Navbar from "@/components/Navbar";
import GuildHeader from "@/domain/guild/GuildHeader";
import Footer from "@/components/Footer";
import Body from "@/components/Body";
import { GuildParams } from "@/types/routes";
import ItemGrid from "@/domain/guild/shop/ItemGrid";
import { useGuild } from "@/api/useGuild";
import { useCurrentMember } from "@/api/useCurrentMember";
import { useShop } from "@/api/useShop";

const Shop = () => {
  const { guildID } = useParams<GuildParams>();

  const { data: guild, isLoading: guildLoading } = useGuild(guildID);
  const { data: currentMember, isLoading: currentMemberLoading } =
    useCurrentMember(guildID);
  const { data: shop, isLoading: shopLoading } = useShop(guildID);

  const loading = guildLoading || shopLoading || currentMemberLoading;

  const availableItems = useMemo(() => {
    if (!shop) return [];
    return shop.filter((item) => item.available);
  }, [shop]);

  const unavailableItems = useMemo(() => {
    if (!shop) return [];
    return shop.filter((item) => !item.available);
  }, [shop]);

  if (!guildID) return <></>;

  return (
    <>
      <Navbar />
      <Body>
        <GuildHeader
          guild={guild}
          tab="shop"
          permission={currentMember?.permission}
        />

        <ItemGrid
          loading={!!loading}
          items={availableItems}
          roles={guild?.roles || []}
          permission={currentMember?.permission}
          draggable={!!currentMember?.permission}
        />

        {unavailableItems.length > 0 && !!currentMember?.permission && (
          <div className="mt-8">
            <h4 className="mb-4 text-lg font-semibold">Hidden</h4>
            <ItemGrid
              loading={!!loading}
              items={unavailableItems}
              roles={guild?.roles || []}
              permission={currentMember?.permission}
            />
          </div>
        )}
      </Body>
      <Footer />
    </>
  );
};

export default Shop;
